import styled from "styled-components";

export const PaginationLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const UnOrdered = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListItem = styled.li<{
  $active?: boolean;
  $buttonSize: number;
}>`
  margin: 0 5px;
  width: ${({ $buttonSize }) => `${$buttonSize}px`};
  height: ${({ $buttonSize }) => `${$buttonSize}px`};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${(props) =>
    props.$active &&
    `background-color: ${props.theme.colors.lightBlue};
    `}
`;
