import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  addAdmin,
  adminData,
  deleteImage,
  editAdminImage,
  editAdminName,
  login,
  LoginPayload,
} from "../api/auth.api";
import useAlert from "../lib/hooks/useAlert";
import { AddAdminPayload } from "../pages/addAdmin/AddAdmin.hook";

export const useLogin = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (req: LoginPayload) => login(req),
    onSuccess: (res) => {
      console.log("login successful");
      console.log(res);
      const { accessToken, refreshToken, info } = res;
      const { type, id } = info;
      console.log(info);
      localStorage.setItem("title", info.name);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("type", type);
      localStorage.setItem("id", id);

      if (info.type === "master") {
        navigate("/master");
      } else {
        navigate("/");
      }
    },

    onError: () => {
      console.log("login failed");
    },
  });
};

export const useGetAdminData = () => {
  return useQuery({
    queryKey: ["admin-data"],
    queryFn: () => adminData(),
  });
};

export const useEditImage = () => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: FormData) => editAdminImage(req),
    onSuccess: () => {
      showToast("success", "이미지 업로드 성공", 2000);
      queryClient.invalidateQueries({
        queryKey: ["admin-data"],
      });
    },
    onError: () => {
      showToast("error", "이미지 업로드 실패", 2000);
    },
  });
};

export const useDeleteImage = () => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: string) => deleteImage(req),
    onSuccess: () => {
      showToast("success", "이미지 삭제 성공", 2000);
      queryClient.invalidateQueries({
        queryKey: ["admin-data"],
      });
    },
    onError: () => {
      showToast("error", "이미지 삭제 실패", 2000);
    },
  });
};

export const useEditName = (handleOffEditName: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: string) => editAdminName(req),
    onSuccess: () => {
      showToast("success", "이름 변경 성공", 2000);
      queryClient.invalidateQueries({
        queryKey: ["admin-data"],
      });
      handleOffEditName();
    },
    onError: () => {
      showToast("error", "이름 변경 실패", 2000);
    },
  });
};

export const useAddAdmin = () => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (req: AddAdminPayload) => addAdmin(req),
    onSuccess: () => {
      showToast("success", "기업 추가 성공", 2000);
      queryClient.invalidateQueries({
        queryKey: ["getAdminList"],
      });
      navigate("/master");
    },
    onError: () => {
      showToast("error", "기업 추가 실패", 2000);
    },
  });
};
