import { useEffect } from "react";
import * as S from "./Modal.style";
import ReactDOM from "react-dom";

interface ModalProps {
  children: React.ReactNode;
  closeModal?: () => void;
}
export default function Modal({ children, closeModal }: ModalProps) {
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);
  const root = document.getElementById("modal-root");
  return ReactDOM.createPortal(
    <S.Overlay onClick={closeModal}>
      <S.ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </S.ModalContent>
    </S.Overlay>,
    root as HTMLElement,
  );
}
