import dayjs from "dayjs";
import React from "react";
import { safeParseAndFormat } from "../../../lib/utils/formatSafeNumber";
import {
  ServerStuff,
  StuffHistoryHeader,
  StuffHistoryResponse,
} from "../../../types/stuff.type";
import Button from "../../common/button/Button";
import Modal from "../../common/modal/Modal";
import { useMasterStuffHistoryTableHook } from "./MasterStuffHistoryTable.hook";
import * as S from "./StuffHistoryTable.style";

const headers: { type: keyof StuffHistoryHeader; name: string }[] = [
  { type: "name", name: "제품명" },
  { type: "image", name: "이미지" },
  { type: "size", name: "규격" },
  { type: "carbon_emissions", name: "탄소배출량/ea" },
  { type: "carbon_unit", name: "단위" },
  { type: "unit_quantity", name: "포장 수량" },
  { type: "carbon", name: "탄소량" },
];

interface StuffHistoryTableProps {
  stuff: StuffHistoryResponse;
}
export default function MasterStuffHistoryTable({
  stuff,
}: StuffHistoryTableProps) {
  const {
    modal,
    handleCloseModal,
    handleOpenEditModal,
    editHistory,
    handleEditChange,
    dateRef,
    handlePickDate,
    handleEditSubmit,
    handleDelete,
    addModal,
    handleOpenAddModal,
    handleCloseAddModal,
    handleAddChange,
    handleAddSubmit,
    addState,
    validateAddHistory,
  } = useMasterStuffHistoryTableHook();

  const returnItem = (
    type: keyof StuffHistoryHeader,

    stuff: StuffHistoryResponse,
  ) => {
    switch (type) {
      case "image":
        return <S.Image key={type} src={stuff.image} alt="product" />;

      case "carbon": {
        if (stuff.histories.length === 0)
          return <S.GridItem key={type}>{0}</S.GridItem>;
        const total = stuff.histories.reduce((acc, cur) => {
          let currentTotal =
            cur.box_quantity * stuff.unit_quantity + cur.single_quantity;
          return acc + currentTotal;
        }, 0);
        return (
          <S.GridItem key={type}>
            {safeParseAndFormat(total * stuff.carbon_emissions || 0)}
          </S.GridItem>
        );
      }

      default:
        return <S.GridItem key={type}>{stuff[type]}</S.GridItem>;
    }
  };

  return (
    <S.Container>
      <S.GridRow>
        {headers.map((item) => (
          <S.GridItem key={item.type} $isHeader>
            {item.name}
          </S.GridItem>
        ))}
        {headers.map((item) => returnItem(item.type, stuff))}
      </S.GridRow>
      <S.InAndOutWrap>
        {/* 출하내역 */}
        <S.InAndOutContainer>
          <S.TitleWrap>
            <S.InAndOutTitle>출하 내역</S.InAndOutTitle>
            <Button width={70} height={30} onClick={() => handleOpenAddModal()}>
              추가
            </Button>
          </S.TitleWrap>
          {stuff.histories && stuff.histories.length > 0 && (
            <S.InAndOutGrid $gtc="2fr 1fr 1fr 1fr 1fr 1fr 1fr">
              <S.GridItem $isHeader>날짜</S.GridItem>
              <S.GridItem $isHeader>포장 수량</S.GridItem>
              <S.GridItem $isHeader>출하 포장 수량</S.GridItem>
              <S.GridItem $isHeader>출하 단품 수량</S.GridItem>
              <S.GridItem $isHeader>출하량</S.GridItem>
              <S.GridItem></S.GridItem>
              <S.GridItem></S.GridItem>
              {stuff.histories.map((item, i) => (
                <React.Fragment key={i}>
                  <S.GridItem>
                    {dayjs(item.created_at).format("YYYY-MM-DD")}
                  </S.GridItem>
                  <S.GridItem>{stuff.unit_quantity}</S.GridItem>
                  <S.GridItem>{item.box_quantity}</S.GridItem>
                  <S.GridItem>{item.single_quantity}</S.GridItem>
                  <S.GridItem>
                    {Math.abs(
                      item.box_quantity * stuff.unit_quantity +
                        item.single_quantity,
                    )}
                  </S.GridItem>
                  <S.GridItem>
                    <Button
                      width={80}
                      height={30}
                      onClick={() => handleOpenEditModal(item)}
                    >
                      수정
                    </Button>
                  </S.GridItem>
                  <S.GridItem>
                    <Button
                      width={80}
                      height={30}
                      backgroundColor="red"
                      onClick={() => handleDelete(item.id)}
                    >
                      삭제
                    </Button>
                  </S.GridItem>
                </React.Fragment>
              ))}
            </S.InAndOutGrid>
          )}
          {(!stuff.histories || stuff.histories.length === 0) && (
            <S.InOutText>출고 내역이 없습니다.</S.InOutText>
          )}
        </S.InAndOutContainer>
      </S.InAndOutWrap>
      {modal && (
        <Modal>
          <S.EditModalContainer>
            <S.InputContainer>
              <S.InputLabel>날짜</S.InputLabel>
              <S.DateDiv onClick={handlePickDate}>
                {dayjs(editHistory?.created_at || "").format("YYYY-MM-DD")}
              </S.DateDiv>
              <S.EditInput
                style={{
                  opacity: 1,
                  visibility: "hidden",
                  width: 0,
                  height: 0,
                }}
                ref={dateRef}
                value={editHistory?.created_at || ""}
                onChange={handleEditChange}
                name="created_at"
                type="date"
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.InputLabel>출하 포장수량</S.InputLabel>
              <S.EditInput
                value={editHistory?.box_quantity || ""}
                onChange={handleEditChange}
                name="box_quantity"
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.InputLabel>출하 단품수량</S.InputLabel>
              <S.EditInput
                value={editHistory?.single_quantity || ""}
                onChange={handleEditChange}
                name="single_quantity"
              />
            </S.InputContainer>
            <S.ModalButtonWrap>
              <Button onClick={handleEditSubmit}>수정</Button>
              <Button backgroundColor="lightBlue" onClick={handleCloseModal}>
                닫기
              </Button>
            </S.ModalButtonWrap>
          </S.EditModalContainer>
        </Modal>
      )}
      {addModal && (
        <Modal>
          <S.AddModalContainer>
            <S.AddInputWrap>
              <S.AddInputLabel>날짜</S.AddInputLabel>
              <S.AddInput
                type="date"
                name="date_time"
                value={addState.date_time}
                onChange={handleAddChange}
              />
            </S.AddInputWrap>
            <S.AddInputWrap>
              <S.AddInputLabel>포장 수량</S.AddInputLabel>
              <S.AddInput
                type="number"
                min={0}
                name="box_quantity"
                value={addState.box_quantity}
                onChange={handleAddChange}
              />
            </S.AddInputWrap>
            <S.AddInputWrap>
              <S.AddInputLabel>단품 수량</S.AddInputLabel>
              <S.AddInput
                type="number"
                min={0}
                name="single_quantity"
                value={addState.single_quantity}
                onChange={handleAddChange}
              />
            </S.AddInputWrap>
            <S.AddButtonWrap>
              <Button
                disabled={!validateAddHistory(addState)}
                onClick={handleAddSubmit}
              >
                추가
              </Button>
              <Button backgroundColor="lightBlue" onClick={handleCloseAddModal}>
                닫기
              </Button>
            </S.AddButtonWrap>
          </S.AddModalContainer>
        </Modal>
      )}
    </S.Container>
  );
}
