import * as XLSX from "xlsx";
// DATA 가공영역
const generateExcelData = (apiData: any, type: "start" | "end" | "add") => {
  // Create Excel rows
  const rows: any[] = [];
  const startHeaders = [
    "제품명",
    "규격",
    "단위 포장수량",
    "시작 포장수량",
    "시작 단품수량",
    "시작 총 수량",
  ];

  const endHeaders = [
    "제품명",
    "규격",
    "단위 포장수량",
    "종료 포장수량",
    "종료 단품수량",
    "종료 총 수량",
  ];
  const addHeaders = [
    "제품명",
    "규격",
    "단위 포장수량",
    "탄소배출량/ea",
    "탄소배출단위",
    "비고",
  ];

  rows.push(
    type === "start" ? startHeaders : type === "end" ? endHeaders : addHeaders,
  );

  // Loop through each product and create rows
  apiData.forEach((product: any) => {
    const startTotal =
      product.start_box_quantity * product.unit_quantity +
      product.start_single_quantity;
    const endTotal =
      product.end_box_quantity * product.unit_quantity +
      product.end_single_quantity;
    const startRow = [
      product.name,
      product.size,
      product.unit_quantity,
      product.start_box_quantity,
      product.start_single_quantity,
      startTotal,
    ];
    const endRow = [
      product.name,
      product.size,
      product.unit_quantity,
      product.end_box_quantity,
      product.end_single_quantity,
      endTotal,
    ];
    const productRow = [
      product.name,
      product.size,
      product.unit_quantity,
      product.carbon_emissions,
      product.carbon_unit,
      product.memo,
    ];

    // Push the row to the rows array
    rows.push(
      type === "start" ? startRow : type === "end" ? endRow : productRow,
    );
  });

  return rows;
};

// Excel 변환 & 다운로드
const handleDownloadExcel = (csvData: any, type: "start" | "end" | "add") => {
  // 워크시트 생성
  const worksheet = XLSX.utils.aoa_to_sheet(csvData);

  // 열 너비 계산
  const columnWidths = csvData[0].map((_: any, colIndex: number) => {
    const maxWidth = Math.max(
      ...csvData.map((row: any) =>
        row[colIndex] ? row[colIndex].toString().length : 10,
      ),
    );
    return { wch: maxWidth + 3 }; // wch: width in characters, +2 for padding
  });

  // 열 너비 적용
  worksheet["!cols"] = columnWidths;

  // 워크북 생성
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    type === "start"
      ? "시작재고수량"
      : type === "end"
      ? "종료재고수량"
      : "제품리스트",
  );

  // 엑셀 파일 다운로드
  XLSX.writeFile(
    workbook,
    type === "start"
      ? "시작재고수량.xlsx"
      : type === "end"
      ? "종료재고수량.xlsx"
      : "제품리스트.xlsx",
  );
};

export const productExcel = (data: any, type: "start" | "end" | "add") => {
  const generatedData = generateExcelData(data, type);
  console.log(generatedData);
  return handleDownloadExcel(generatedData, type);
};
