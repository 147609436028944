import { useCallback, useEffect, useRef, useState } from "react";
import useAlert from "../../lib/hooks/useAlert";
import {
  useDeleteImage,
  useEditImage,
  useEditName,
  useGetAdminData,
} from "../../services/auth.service";

export const useSettingHook = () => {
  const [editNameMode, setEditNameMode] = useState(false);
  const [editName, setEditName] = useState("");
  const { data: adminData } = useGetAdminData();
  //   const [uploadImgFile, setUploadImgFile] = useState<File | null>(null);

  const { showConfirmation, showError } = useAlert();

  // 여러개의 ref를 배열로 관리
  const imageInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  // ref를 할당하는 함수
  const setImageInputRef = useCallback(
    (el: HTMLInputElement | null, index: number) => {
      imageInputRefs.current[index] = el;
    },
    [],
  );

  const handleOnEditName = () => {
    setEditNameMode(true);
  };
  const handleOffEditName = () => {
    setEditNameMode(false);
  };

  const { mutate: editNameMutate } = useEditName(handleOffEditName);
  const { mutate: editImageMutate } = useEditImage();
  const { mutate: deleteImage } = useDeleteImage();

  const handleSubmitName = () => {
    if (editName.trim() === "") {
      showError("이름을 입력해주세요");
      return;
    }
    editNameMutate(editName);
  };

  const handleDeleteImage = (index: string) => {
    showConfirmation("삭제", "해당 이미지를 삭제하시겠습니까?", () => {
      deleteImage(index);
    });
  };

  const handleUploadClick = (index: number) => {
    console.log(index, "클릭");
    // 특정 ref에 접근해서 해당 input을 클릭
    if (imageInputRefs.current[index]) {
      console.log("여기");
      imageInputRefs.current[index]?.click();
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const index = e.target.name;

    const formData = new FormData();
    if (file) {
      formData.append("image", file);
      formData.append("index", index);
      editImageMutate(formData);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditName(e.target.value);
  };

  useEffect(() => {
    if (adminData && adminData.name) {
      setEditName(adminData.name);
    }
  }, [adminData]);

  return {
    adminData,
    editName,
    handleNameChange,
    editNameMode,
    handleOnEditName,
    handleOffEditName,
    setImageInputRef,
    handleUploadClick,
    handleImageChange,
    handleDeleteImage,
    handleSubmitName,
  };
};
