import { useNavigate, useParams } from "react-router-dom";
import usePage from "../../lib/hooks/usePage";
import {
  GET_ADMIN_PRODUCTS_QUERY_KEY,
  useDeleteMasterAdminProduct,
  useDeleteMasterAdminStuff,
  useGetAdminProducts,
  useGetAdminStuffs,
  useUpdateMasterAdminProduct,
  useUpdateMasterAdminStuff,
} from "../../services/master.service";
import useAlert from "../../lib/hooks/useAlert";
import { ChangeEvent, ChangeEventHandler, useRef, useState } from "react";
import { Product } from "../../types/products";
import returnMutationErrorMessage from "../../lib/utils/returnMutationErrorMessage";
import { useQueryClient } from "@tanstack/react-query";
import { ServerStuff } from "../../types/stuff.type";

const productColumns: { name: string; type: keyof Product }[] = [
  {
    name: "제품명",
    type: "name",
  },
  {
    name: "이미지",
    type: "image",
  },
  {
    name: "규격",
    type: "size",
  },
  {
    name: "포장수량",
    type: "unit_quantity",
  },
  {
    name: "탄소배출량/ea",
    type: "carbon_emissions",
  },
  {
    name: "단위",
    type: "carbon_unit",
  },
  {
    name: "비고",
    type: "memo",
  },
];

const stuffColumns = [...productColumns];

const useMasterAdmin = () => {
  const navigate = useNavigate();
  const { admin_id } = useParams();
  const {
    pageNum,
    onClickPageHandler,
    filter = "product",
    onFilterChangeHandler,
  } = usePage();
  const { showConfirmation } = useAlert();

  // 제품 || 부자재 선택
  // const [productOrStuff, setProductOrStuff] = useState<"product" | "stuff">(
  //   "product",
  // );

  // 삭제 / 수정 물어보는 모달
  const [showEditDeleteModal, setShowEditDeleteModal] = useState(false);

  // 삭제 / 수정 관련
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
    undefined,
  );
  const [selectedStuff, setSelectedStuff] = useState<ServerStuff | undefined>(
    undefined,
  );
  const [editModal, setEditModal] = useState(false);

  const [imagePreview, setImagePreview] = useState("");
  const [editImage, setEditImage] = useState<File | null>(null);
  const imageRef = useRef<HTMLInputElement>(null);

  // Query, Mutation
  const queryClient = useQueryClient();
  const { data: masterAdminProducts } = useGetAdminProducts(
    Number(admin_id),
    pageNum,
    filter === "product" || filter === "",
  );
  const { data: masterAdminStuffs } = useGetAdminStuffs(
    Number(admin_id),
    pageNum,
    filter === "stuff",
  );

  const deleteProduct = useDeleteMasterAdminProduct();
  const deleteStuff = useDeleteMasterAdminStuff();
  const updateProduct = useUpdateMasterAdminProduct();
  const updateStuff = useUpdateMasterAdminStuff();

  //   console.log("제품 DATA", masterAdminProducts);
  //   console.log("부자재 DATA", masterAdminStuffs);

  // Functions
  const handleNavigateToAdd = () => {
    if (filter === "product") {
      navigate(`/master/admin/${admin_id}/product/add`);
    } else {
      navigate(`/master/admin/${admin_id}/stuff/add`);
    }
  };

  const validateProduct = () => {
    const { name, size, unit_quantity, carbon_emissions, carbon_unit } =
      selectedProduct || {};

    return Boolean(
      (name?.trim() || "").length > 0 && // 이름이 존재하고 공백이 아닌지 확인
        (size?.trim() || "").length > 0 && // 사이즈가 존재하고 공백이 아닌지 확인
        unit_quantity !== undefined &&
        unit_quantity >= 0, // 수량이 0보다 큰지 확인
      // carbon_emissions !== undefined &&
      // carbon_emissions >= 0 && // 탄소 배출량이 0보다 큰지 확인
      // (carbon_unit?.trim() || "").length > 0, // 단위가 존재하고 공백이 아닌지 확인
    );
  };

  const validateStuff = () => {
    const { name, size, unit_quantity, carbon_emissions, carbon_unit } =
      selectedStuff || {};

    return Boolean(
      (name?.trim() || "").length > 0 && // 이름이 존재하고 공백이 아닌지 확인
        (size?.trim() || "").length > 0 && // 사이즈가 존재하고 공백이 아닌지 확인
        unit_quantity !== undefined &&
        unit_quantity >= 0, // 수량이 0보다 큰지 확인
      // carbon_emissions !== undefined &&
      // carbon_emissions >= 0 && // 탄소 배출량이 0보다 큰지 확인
      // (carbon_unit?.trim() || "").length > 0, // 단위가 존재하고 공백이 아닌지 확인
    );
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setEditImage(file);
      // 파일을 읽고 미리보기 이미지 생성
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string); // 미리보기 상태 업데이트
      };
      reader.readAsDataURL(file); // 파일을 Data URL로 읽기
    }
  };

  const handleProductOrStuff = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    // setProductOrStuff(value as typeof productOrStuff);
    onFilterChangeHandler("filter", value);
    setSelectedProduct(undefined);
    setSelectedStuff(undefined);
  };

  const onClickProductOrStuff = (
    type: "product" | "stuff",
    item: Product | ServerStuff,
  ) => {
    if (type === "product") {
      setSelectedProduct(item as Product);
    } else {
      setSelectedStuff(item as ServerStuff);
    }

    setShowEditDeleteModal(true);
  };

  const handleCloseEditOrDeleteModal = () => {
    setShowEditDeleteModal(false);
    setSelectedProduct(undefined);
    setSelectedStuff(undefined);
  };

  const showEdit = () => {
    setEditModal(true);
    setShowEditDeleteModal(false);
  };

  const handleCloseModal = () => {
    setEditModal(false);
    setImagePreview("");
    setEditImage(null);
    setSelectedProduct(undefined);
    setSelectedStuff(undefined);
  };

  const handleClickImage = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleDeleteProduct = (product_id: number) => {
    showConfirmation("확인", "해당 제품을 정말 삭제하시겠습니까?", () => {
      deleteProduct(
        { admin_id: Number(admin_id), product_id },
        {
          onSuccess: () => {
            alert("성공");
          },
          onError: (error) => {
            alert(returnMutationErrorMessage(error));
          },
          onSettled: () => {
            handleCloseEditOrDeleteModal();
            queryClient.invalidateQueries({
              queryKey: [...GET_ADMIN_PRODUCTS_QUERY_KEY],
            });
          },
        },
      );
    });
  };

  const handleDeleteSTUFF = (stuff_id: number) => {
    showConfirmation("확인", "해당 제품을 정말 삭제하시겠습니까?", () => {
      deleteStuff(
        { admin_id: Number(admin_id), stuff_id },
        {
          onSuccess: () => {
            alert("성공");
          },
          onError: (error) => {
            alert(returnMutationErrorMessage(error));
          },
          onSettled: () => {
            setShowEditDeleteModal(false);
            setSelectedProduct(undefined);
            setSelectedStuff(undefined);
            queryClient.invalidateQueries({
              queryKey: [...GET_ADMIN_PRODUCTS_QUERY_KEY],
            });
          },
        },
      );
    });
  };

  const handleEditProductChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    //@ts-ignore
    setSelectedProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditStuffChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    //@ts-ignore
    setSelectedStuff((prev) => ({ ...prev, [name]: value }));
  };

  const onClickDelete = () => {
    if (!selectedProduct && !selectedStuff) {
      alert("삭제할 수 없습니다.");
      return;
    }

    if (selectedProduct) {
      handleDeleteProduct(selectedProduct.id!);
      return;
    }
    if (selectedStuff) {
      handleDeleteSTUFF(selectedStuff.id);
    }
  };

  const handleSubmitProduct = () => {
    if (!selectedProduct) return;
    const {
      id,
      name,
      size,
      unit_quantity,
      carbon_emissions,
      carbon_unit,
      memo,
    } = selectedProduct;

    if (!name || name === "") {
      alert("제품명을 입력해주세요");
      return;
    }

    if (!unit_quantity || unit_quantity === 0) {
      alert("포장 수량을 입력해주세요");
      return;
    }

    if (!id || !size) {
      alert("입력을 확인해주세요.");
      return;
    }

    const formData = new FormData();
    formData.append("id", id.toString());
    formData.append("name", name);
    formData.append("size", size);
    formData.append("unit_quantity", unit_quantity.toString());
    formData.append(
      "carbon_emissions",
      carbon_emissions ? carbon_emissions.toString() : "",
    );
    formData.append("carbon_unit", carbon_unit || "0");
    formData.append("memo", memo || "");

    // editImage가 있으면 이를 FormData에 추가
    if (editImage) {
      formData.append("image", editImage);
    }

    // 마스터에서는 admin_id formData로 전송!
    formData.append("admin_id", admin_id!);

    // editState의 데이터를 FormData로 전송
    updateProduct(
      {
        payload: formData,
        admin_id: Number(admin_id),
        product_id: id,
      },
      {
        onSuccess: () => {
          alert("성공");
        },
        onError: (error) => {
          alert(returnMutationErrorMessage(error));
        },
        onSettled: () => {
          queryClient.invalidateQueries();
          handleCloseModal();
        },
      },
    );
  };

  const handleSubmitStuff = () => {
    if (!selectedStuff) return;
    const {
      id,
      name,
      size,
      unit_quantity,
      carbon_emissions,
      carbon_unit,
      memo,
    } = selectedStuff;

    if (!id || !name || !size || !unit_quantity) {
      console.log("여기");
      return;
    }

    const formData = new FormData();
    formData.append("id", id.toString());
    formData.append("name", name);
    formData.append("size", size);
    formData.append("unit_quantity", unit_quantity.toString());
    formData.append(
      "carbon_emissions",
      carbon_emissions ? carbon_emissions.toString() : "",
    );
    formData.append("carbon_unit", carbon_unit || "");
    formData.append("memo", memo || "");

    // editImage가 있으면 이를 FormData에 추가
    if (editImage) {
      formData.append("image", editImage);
    }

    // // 마스터에서는 admin_id formData로 전송!
    // formData.append("admin_id", admin_id!);

    // editState의 데이터를 FormData로 전송
    updateStuff(
      {
        payload: formData,
        admin_id: Number(admin_id),
        stuff_id: id,
      },
      {
        onSuccess: () => {
          alert("성공");
        },
        onError: (error) => {
          alert(returnMutationErrorMessage(error));
        },
        onSettled: () => {
          queryClient.invalidateQueries();
          handleCloseModal();
        },
      },
    );
  };

  const onClickGoDetail = () => {
    if (selectedProduct) {
      const path = `/master/admin/${admin_id}/product/${selectedProduct.id!}`;
      navigate(path);
      return;
    }

    if (selectedStuff) {
      const path = `/master/admin/${admin_id}/stuff/${selectedStuff.id}`;
      navigate(path);
      return;
    }
  };

  return {
    productColumns,
    stuffColumns,
    admin_id,
    pageNum,
    onClickPageHandler,
    // productOrStuff,
    masterAdminProducts,
    masterAdminStuffs,
    handleNavigateToAdd,
    handleProductOrStuff,
    showEditDeleteModal,
    onClickProductOrStuff,
    handleCloseEditOrDeleteModal,
    onClickDelete,
    editModal,
    selectedProduct,
    selectedStuff,
    imageRef,
    handleCloseModal,
    handleClickImage,
    showEdit,
    handleImageChange,
    imagePreview,
    validateProduct,
    validateStuff,
    handleSubmitProduct,
    handleEditProductChange,
    handleEditStuffChange,
    handleSubmitStuff,
    onClickGoDetail,
    filter,
  };
};

export default useMasterAdmin;
