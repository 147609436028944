import * as S from "./MasterStuffOutPage.style";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import { useMasterStuffOutPageHook } from "./MasterStuffOutPage.hook";
import StuffHistoryTable from "../../components/ui/stuffHistoryTable/StuffHistoryTable";
import MasterStuffHistoryTable from "../../components/ui/stuffHistoryTable/MasterStuffHistoryTable";

const MasterStuffOutPage = () => {
  const {
    date,
    handleSetDate,
    firstDateRef,
    endDateRef,
    handleClickDate,
    masterStuffHistories,
    allStuff,
    selectStuffId,
    handleSelectChange,
    stuff_id,
  } = useMasterStuffOutPageHook();

  return (
    <PageLayout title="부자재 사용 내역">
      <S.Container>
        <S.ToolContainer>
          <S.ToolRow>
            <S.ToolTag>기간 설정</S.ToolTag>
            <S.DateContainer>
              <S.DateString onClick={() => handleClickDate("firstDate")}>
                {date.firstDate}
                <S.InputDate
                  ref={firstDateRef}
                  name="firstDate"
                  onChange={handleSetDate}
                  value={date.firstDate}
                  type="date"
                />
              </S.DateString>
              ~
              <S.DateString onClick={() => handleClickDate("endDate")}>
                {date.endDate}
                <S.InputDate
                  ref={endDateRef}
                  name="endDate"
                  onChange={handleSetDate}
                  value={date.endDate}
                  type="date"
                />
              </S.DateString>
            </S.DateContainer>
          </S.ToolRow>
          <S.ToolRow>
            <S.ToolTag>제품 선택</S.ToolTag>
            {/*TODO: 여기에 select로 용기 고를 수 있도록 하기 */}
            <select
              style={{
                minWidth: 150,
                padding: 5,
                fontSize: 14,
                borderRadius: 8,
              }}
              value={parseInt(stuff_id || "0")}
              onChange={handleSelectChange}
            >
              {allStuff?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </S.ToolRow>
        </S.ToolContainer>
        {masterStuffHistories?.map((item: any) => (
          <MasterStuffHistoryTable key={item.id} stuff={item} />
        ))}
      </S.Container>
    </PageLayout>
  );
};

export default MasterStuffOutPage;
