import * as S from "./ProductAdd.style";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import useProductAdd from "./ProductAdd.hook";
import { Product } from "../../types/products";
import defaultImage from "../../assets/images/upload_image_default.png";
import Button from "../../components/common/button/Button";
import Modal from "../../components/common/modal/Modal";

const inputList: {
  label: string;
  name: keyof Product;
  type: string;
  placeholder: string;
}[] = [
  {
    label: "제품명",
    name: "name",
    type: "text",
    placeholder: "제품명을 입력하세요.",
  },
  {
    label: "규격",
    name: "size",
    type: "text",
    placeholder: "규격을 입력하세요.",
  },
  {
    label: "포장수량",
    name: "unit_quantity",
    type: "number",
    placeholder: "포장수량을 숫자로 입력하세요.",
  },
  {
    label: "탄소배출량",
    name: "carbon_emissions",
    type: "number",
    placeholder: "탄소배출량을 숫자로 입력하세요.",
  },
  {
    label: "단위",
    name: "carbon_unit",
    type: "text",
    placeholder: "탄소배출량의 단위를 입력하세요.",
  },
  {
    label: "비고",
    name: "memo",
    type: "text",
    placeholder: "비고는 입력하지 않아도 괜찮습니다.",
  },
];

export default function ProductAdd() {
  const {
    product,
    handleChangeProduct,
    handleChangeImage,
    imagePreview,
    imageInputRef,
    handleClickImageInput,
    validateProduct,
    handleSubmit,
  } = useProductAdd();

  return (
    <PageLayout title="제품 등록">
      <S.Container>
        {inputList.map((item, i) => (
          <S.InputWrapper key={i}>
            <S.Label>{item.label}</S.Label>
            <S.Input
              name={item.name}
              //@ts-ignore
              value={product[item.name]}
              onChange={handleChangeProduct}
              placeholder={item.placeholder}
            />
          </S.InputWrapper>
        ))}
        <S.InputWrapper>
          <S.Label>이미지 등록</S.Label>
          <S.Input
            type="file"
            accept="image/*"
            onChange={handleChangeImage}
            ref={imageInputRef}
            style={{ display: "none" }}
          />
          <S.ImageInputBox onClick={handleClickImageInput}>
            <S.PreviewImage
              src={imagePreview ? imagePreview : defaultImage}
              alt="미리보기"
            />
            {!imagePreview && <span>이미지가 없습니다.</span>}
          </S.ImageInputBox>
        </S.InputWrapper>
        <S.ButtonWrapper>
          <Button disabled={!validateProduct()} onClick={handleSubmit}>
            등록하기
          </Button>
          <Button backgroundColor="red">뒤로가기</Button>
        </S.ButtonWrapper>
      </S.Container>
    </PageLayout>
  );
}
