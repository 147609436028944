import {
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PreviousPageIcon,
} from "../../../assets/svgs/paginationIcon";
import usePagination from "./Pagination.hook";
import * as S from "./Pagination.style";

interface PaginationProps {
  totalPages: number;
  size: number;
  currentPage: number;
  buttonSize?: number;
  iconSize?: number;
  onClickPage: (pageNumber: number) => void;
}

export default function Pagination({
  totalPages,
  size,
  buttonSize = 30,
  onClickPage,
  currentPage,
  iconSize = 18,
}: PaginationProps) {
  const { createPagination } = usePagination();

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber <= 0) return null;
    else if (pageNumber > totalPages) return null;
    else onClickPage(pageNumber);
  };
  const pagination = createPagination({ totalPages, currentPage, size });

  return (
    <S.PaginationLayout>
      <FirstPageIcon width={iconSize} onClick={() => handlePageClick(1)} />

      <PreviousPageIcon
        width={iconSize}
        onClick={() => handlePageClick(currentPage - 1)}
      />

      <S.UnOrdered>
        {pagination.map((pageNumber) => (
          <S.ListItem
            $buttonSize={buttonSize}
            key={pageNumber}
            $active={pageNumber === currentPage}
            onClick={() => handlePageClick(pageNumber)}
          >
            {pageNumber}
          </S.ListItem>
        ))}
      </S.UnOrdered>

      <NextPageIcon
        width={iconSize}
        onClick={() => handlePageClick(currentPage + 1)}
      />

      <LastPageIcon
        width={iconSize}
        onClick={() => handlePageClick(totalPages)}
      />
    </S.PaginationLayout>
  );
}

/* 
  첫페이지 비활성 조건 : currentPage !== 1 && totalPages > 5
  마지막페이지 비활성 조건 :currentPage !== totalPages && totalPages > 5 
  */
