import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  gap: 3px;
  overflow-y: auto;
`;

export const GridRow = styled.div<{ $isHeader?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 100px 2fr 1fr 1fr 1fr 3fr;
  grid-row-gap: 3px;
  grid-column-gap: 3px;
  &:hover {
    ${({ theme, $isHeader }) =>
      !$isHeader && `background-color:${theme.colors.borderGray}`};
    cursor: pointer;
  }
  &:hover > div {
    background-color: transparent;
  }
`;

export const GridItem = styled.div<{ $isHeader?: boolean }>`
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tableBlue};
  ${({ $isHeader }) => $isHeader && `font-weight:700`};
`;

export const Image = styled.img`
  width: 100px;
  max-height: 50px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
`;

/* Modal */
export const EditModalContainer = styled.div`
  width: 800px;
  height: 90vh;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 40px;
  overflow-y: auto;
`;

export const ModalInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const InputLabel = styled.p`
  font-size: 20px;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  font-size: 18px;
`;

export const PreviewImage = styled.img`
  width: 200px;
  height: 200px;

  object-fit: contain;
`;

export const ButtonWrap = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
