import {
  addMasterStuffHistory,
  AddMasterStuffHistoryPayload,
} from "./../api/masterStuff.api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteMasterStuffHistory,
  editMasterStuffHistory,
  EditMasterStuffHistoryPayload,
  getMasterStuffAll,
  getMasterStuffHistories,
} from "../api/masterStuff.api";
import useAlert from "../lib/hooks/useAlert";

export const useMasterStuffHistories = (
  adminId: string,
  stuffId: string,
  pageNum: number,
  firstDate: string,
  endDate: string,
) => {
  return useQuery({
    queryKey: [
      "master-stuff-histories",
      adminId,
      stuffId,
      pageNum,
      firstDate,
      endDate,
    ],
    queryFn: () =>
      getMasterStuffHistories(adminId, stuffId, pageNum, firstDate, endDate),
  });
};

export const useMasterAllStuff = (adminId: string) => {
  return useQuery({
    queryKey: ["all-Stuff", adminId],
    queryFn: () => getMasterStuffAll(adminId),
  });
};

export const useMasterEditStuffHistory = (handleCloseModal: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      adminId,
      historyId,
      payload,
    }: {
      adminId: string;
      historyId: number;
      payload: EditMasterStuffHistoryPayload;
    }) => editMasterStuffHistory(adminId, historyId, payload),
    onSuccess: () => {
      showToast("success", "부자재 수정 성공!", 2000);
      queryClient.invalidateQueries({ queryKey: ["master-stuff-histories"] });
      handleCloseModal();
    },
    onError: () => {
      showToast("error", "부자재 수정 실패", 2000);
    },
  });
};

export const useMasterDeleteStuffHistory = () => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      adminId,
      historyId,
    }: {
      adminId: string;
      historyId: number;
    }) => deleteMasterStuffHistory(adminId, historyId),
    onSuccess: () => {
      showToast("success", "부자재 수정 성공!", 2000);
      queryClient.invalidateQueries({ queryKey: ["master-stuff-histories"] });
    },
    onError: () => {
      showToast("error", "부자재 수정 실패", 2000);
    },
  });
};

export const useMasterAddStuffHistory = (handleCloseAddModal: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      adminId,
      payload,
    }: {
      adminId: string;
      payload: AddMasterStuffHistoryPayload;
    }) => addMasterStuffHistory(adminId, payload),

    onSuccess: () => {
      showToast("success", "부자재 추가 성공!", 2000);
      queryClient.invalidateQueries({ queryKey: ["master-stuff-histories"] });
      handleCloseAddModal();
    },

    onError: () => {
      showToast("error", "부자재 추가 실패", 2000);
    },
  });
};
