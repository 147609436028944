import { Paging } from "../types/common.type";
import { ProductInoutResponse, ServerProduct } from "../types/products";
import api from "./axios";

export const addProduct = async (payload: FormData) => {
  const { data } = await api.post("/product", payload);
  return data.data;
};

export const getProducts = async (
  pageNum: number,
): Promise<Paging<ServerProduct>> => {
  const { data } = await api.get("/product", {
    params: {
      pageNum,
    },
  });
  return data.data;
};

export const editProduct = async (payload: FormData) => {
  const { data } = await api.put("/product/edit", payload);
  return data.data;
};

export const deleteProduct = async (productId: number) => {
  const { data } = await api.delete("/product", { data: { id: productId } });
  return data.data;
};

export interface GetInOutPayload {
  productId?: number;
  firstDate: string;
  endDate: string;
}
export const getInOutData = async (
  payload: GetInOutPayload,
): Promise<ProductInoutResponse[]> => {
  const { data } = await api.get("/product/in-out-web", { params: payload });
  return data.data;
};

export const getAllProducts = async (): Promise<
  { id: number; name: string }[]
> => {
  const { data } = await api.get("/product/list");
  return data.data;
};

export interface EditStartQuantityPayload {
  productId: number;
  start_box_quantity: number;
  start_single_quantity: number;
}
export const editStartQuantity = async (payload: EditStartQuantityPayload) => {
  const { data } = await api.put("/product/edit/start", payload);
  return data.data;
};

export interface EditEndQuantityPayload {
  productId: number;
  end_box_quantity: number;
  end_single_quantity: number;
}
export const editEndQuantity = async (payload: EditEndQuantityPayload) => {
  const { data } = await api.put("/product/edit/end", payload);
  return data.data;
};
