import * as S from "./ProductionHistory.style";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import ProductHistoryTable from "../../components/ui/productHistoryTable/ProductHistoryTable";
import useProductHistory from "./ProductHistory.hook";

export default function ProductHistory() {
  const {
    date,
    handleSetDate,
    firstDateRef,
    endDateRef,
    handleClickDate,
    inOutData,
    allProduct,
    selectProductId,
    handleSelectChange,
    handleClickExcel,
  } = useProductHistory();

  return (
    <PageLayout
      title="제품 입출하 내역"
      showExcel
      getExcelFunction={handleClickExcel}
    >
      <S.Container>
        <S.ToolContainer>
          <S.ToolRow>
            <S.ToolTag>기간 설정</S.ToolTag>
            <S.DateContainer>
              <S.DateString onClick={() => handleClickDate("firstDate")}>
                {date.firstDate}
                <S.InputDate
                  ref={firstDateRef}
                  name="firstDate"
                  onChange={handleSetDate}
                  value={date.firstDate}
                  type="date"
                />
              </S.DateString>
              ~
              <S.DateString onClick={() => handleClickDate("endDate")}>
                {date.endDate}
                <S.InputDate
                  ref={endDateRef}
                  name="endDate"
                  onChange={handleSetDate}
                  value={date.endDate}
                  type="date"
                />
              </S.DateString>
            </S.DateContainer>
          </S.ToolRow>
          <S.ToolRow>
            <S.ToolTag>제품 설정</S.ToolTag>
            {/*TODO: 여기에 select로 용기 고를 수 있도록 하기 */}
            <select
              style={{
                minWidth: 150,
                padding: 5,
                fontSize: 14,
                borderRadius: 8,
              }}
              value={selectProductId}
              onChange={handleSelectChange}
            >
              <option key="default" value={0}>
                전체보기
              </option>
              {allProduct?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </S.ToolRow>
        </S.ToolContainer>
        {inOutData?.map((item) => (
          <ProductHistoryTable key={item.id} product={item} />
        ))}
      </S.Container>
    </PageLayout>
  );
}
