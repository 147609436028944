import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
`;

export const Form = styled.form`
  width: 400px;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const InputWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputLabel = styled.p`
  font-size: 20px;
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

export const ValidatePassword = styled.p`
  color: red;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
