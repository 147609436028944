import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  gap: 30px;
`;

export const ToolContainer = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ToolRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const ToolTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.borderGray};
  padding: 5px 10px;
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const DateString = styled.p`
  cursor: pointer;
`;

export const InputDate = styled.input`
  opacity: 1;
  visibility: hidden;
  width: 0px;
  height: 0px;
`;
