import dayjs from "dayjs";
import { useRef, useState } from "react";
import { getStuffHistoriesExcelData } from "../../api/excel.api";
import { stuffHistoriesExcel } from "../../lib/utils/excel/stuffHistories";
import { useAllProducts } from "../../services/product.service";
import {
  useGetAllStuff,
  useGetStuffHistory,
} from "../../services/stuff.service";

export const useStuffHistory = () => {
  const endDate = dayjs().format("YYYY-MM-DD");
  const firstDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const [selectStuffId, setSelectStuffId] = useState(0);
  const [date, setDate] = useState({
    firstDate: firstDate || "",
    endDate: endDate || "",
  });
  const firstDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);

  const { data: histories } = useGetStuffHistory({
    ...date,
    stuffId: selectStuffId,
  });

  const { data: allStuff } = useGetAllStuff();

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectStuffId(parseInt(value));
  };

  const handleSetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDate((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickDate = (type: "firstDate" | "endDate") => {
    if (type === "firstDate") {
      firstDateRef.current?.showPicker();
      console.log("hi");
      return;
    }
    if (type === "endDate") {
      endDateRef.current?.showPicker();
      return;
    }
  };

  const handleClickExcel = async () => {
    const data = await getStuffHistoriesExcelData();
    return stuffHistoriesExcel(data);
  };

  return {
    date,
    handleSetDate,
    firstDateRef,
    endDateRef,
    handleClickDate,
    histories,
    allStuff,
    selectStuffId,
    handleSelectChange,
    handleClickExcel,
  };
};
