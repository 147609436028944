import { AddAdminPayload } from "../pages/addAdmin/AddAdmin.hook";
import api from "./axios";

export interface LoginPayload {
  id: string;
  password: string;
}
export const login = async (payload: LoginPayload) => {
  const { data } = await api.post("/admin/login", payload);
  return data.data;
};

export const adminData = async () => {
  const { data } = await api.get("/admin/data");
  return data.data;
};

export const editAdminName = async (name: string) => {
  const { data } = await api.put("/admin/name", { name });
  return data.data;
};

export const editAdminImage = async (payload: FormData) => {
  const { data } = await api.put("/admin/image", payload);
  return data.data;
};

export const deleteImage = async (index: string) => {
  const { data } = await api.put("/admin/image/delete", { index });
  return data.data;
};

export const addAdmin = async (payload: AddAdminPayload) => {
  const { data } = await api.post("/admin/admins", payload);
  return data.data;
};
