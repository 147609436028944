import { colors } from "../../../theme/theme";
import * as S from "./Button.style";

interface ButtonProps {
  width?: number;
  height?: number;
  backgroundColor?: keyof typeof colors;
  fontColor?: keyof typeof colors;
  border?: boolean;
  borderColor?: keyof typeof colors;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  fontWeight?: number;
}
export default function Button({
  children,
  width = 150,
  height = 50,
  backgroundColor = "blue",
  fontColor = "white",
  border = false,
  borderColor = "borderGray",
  onClick,
  fontWeight = 800,
  disabled = false,
}: ButtonProps) {
  return (
    <S.Container
      $disabled={disabled}
      onClick={disabled ? undefined : onClick}
      $width={width}
      $height={height}
      $backgroundColor={disabled ? "gray" : backgroundColor}
      $fontColor={fontColor}
      $border={border}
      $borderColor={borderColor}
      $fontWeight={fontWeight}
    >
      {children}
    </S.Container>
  );
}
