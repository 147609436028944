import { useSearchParams } from "react-router-dom";

export default function usePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNum = parseInt(searchParams.get("page") || "1");
  const filter = searchParams.get("filter") || "";
  const keyword = searchParams.get("keyword") || "";
  const multiFilter = searchParams.get("multiFilter") || "";
  const sort = searchParams.get("sort") || "";
  const type = searchParams.get("type") || "";
  const status = searchParams.get("status") || "";

  const onKeywordChangeHandler = (keyword: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("page", "1");
    newSearchParams.set("keyword", keyword);
    setSearchParams(newSearchParams);
    window.scrollTo(0, 0);
  };

  const onClickPageHandler = (page: number) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
    window.scrollTo(0, 0);
  };

  const onFilterChangeHandler = (key: string, selected: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set(key, selected);
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
    window.scrollTo(0, 0);
  };

  const onInitialize = () => {
    setSearchParams(new URLSearchParams());
    window.scrollTo(0, 0);
  };

  return {
    pageNum,
    filter,
    keyword,
    // sortBy,
    multiFilter,
    onKeywordChangeHandler,
    onClickPageHandler,
    onFilterChangeHandler,
    // onSortChangeHandler,
    onInitialize,
    sort,
    type,
    status,
    // onMultiFilterChangeHandler,
  };
}
