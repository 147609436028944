import dayjs from "dayjs";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  InOutHistory,
  ProductHistoryTableHeader,
  ProductInoutResponse,
} from "../../../types/products";
import styled from "styled-components";
import formatKoreanWon from "../../../lib/utils/FormatKoreanWon";
import Button from "../../common/button/Button";
import {
  useCreateMasterAdminProductInout,
  useDeleteMasterAdminProductInout,
  useUpdateMasterAdminProductInout,
} from "../../../services/master.service";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import returnMutationErrorMessage from "../../../lib/utils/returnMutationErrorMessage";
import useAlert from "../../../lib/hooks/useAlert";
import Modal from "../../common/modal/Modal";
import {
  ICreateProductInoutPayload,
  IUpdateMasterAdminProductInoutPayload,
} from "../../../types/master.types";

/**
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
*/

/**
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
*/
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  gap: 50px;
`;

const GridRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-row-gap: 20px;
`;

const GridItem = styled.div<{ $isHeader?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $isHeader }) => $isHeader && `font-weight:500`};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const InAndOutWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  margin-top: 20px;
  justify-content: center;
  align-items: flex-start;
`;
const InAndOutContainer = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 1;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
`;

const InAndOutTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const InAndOutTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
`;

const InAndOutGrid = styled.div`
  width: 600px;
  display: grid;
  // 2024-08-22 원본과 다르게 버튼과 box, single quantity 들어가서 4개 추가
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 20px;
`;

const InOutText = styled.p``;

const EditModalContainer = styled.div`
  width: 50vw;
  min-width: 600px;
  /* height: 85dvh;
  min-height: 600px; */
  height: fit-content;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  padding: 3dvh 5vw;
  gap: 16px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ModalRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 24px;
`;

const ModalBtnSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
`;

const Input = styled.input``;

const DateInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

/**
----------------------------------------------- 🖼️ 렌더링 🖼️ -----------------------------------------------
----------------------------------------------- 🖼️ 렌더링 🖼️  -----------------------------------------------
----------------------------------------------- 🖼️ 렌더링 🖼️  -----------------------------------------------
----------------------------------------------- 🖼️ 렌더링 🖼️  -----------------------------------------------
*/

const headers: { type: keyof ProductHistoryTableHeader; name: string }[] = [
  { type: "name", name: "제품명" },
  { type: "image", name: "이미지" },
  { type: "size", name: "규격" },
  { type: "carbon_emissions", name: "탄소배출량/ea" },
  { type: "carbon_unit", name: "단위" },
  { type: "unit_quantity", name: "포장 수량" },
  { type: "start_quantity", name: "시작재고 수량" },
  { type: "end_quantity", name: "종료재고 수량" },
  { type: "missing_quantity", name: "분실,파손 수량" },
  { type: "carbon", name: "탄소량" },
];

interface ProductHistoryTableProps {
  product: ProductInoutResponse;
}
export default function MasterProductHistoryTable({
  product,
}: ProductHistoryTableProps) {
  const { name } = product;
  const { admin_id, product_id } = useParams();
  const { showConfirmation } = useAlert();

  /**
   * showEdit 으로 보이는 모달 1개 가지고 수정 / 추가를 모두 컨트롤
   * editType State로 추가 / 수정을 결정한다.
   */
  const [showEdit, setShowEdit] = useState(false);
  const [addType, setAddType] = useState<"in" | "out" | undefined>(undefined);
  const [selectedHistory, setSelectedHistory] = useState<
    InOutHistory | undefined
  >(undefined);
  //
  const [boxQuantity, setBoxQuantity] = useState("");
  const [singleQuantity, setSingleQuantity] = useState("");
  const [totalQuantity, setTotalQantity] = useState(0);
  const [date, setDate] = useState<string | undefined>(undefined);
  const dateInputRef = useRef<HTMLInputElement | null>(null);

  const outbound = product.outbound_histories;
  const inbound = product.inbound_histories;

  // Mutation
  const queryClient = useQueryClient();
  const deleteProductHistory = useDeleteMasterAdminProductInout();
  const updateProductHistory = useUpdateMasterAdminProductInout();
  const createProductHistory = useCreateMasterAdminProductInout();

  // Functions
  const onClickDelete = (history_id: number) => {
    showConfirmation(
      "삭제 하시겠습니까?",
      "삭제 후에는 복구가 불가능합니다.",
      () => {
        deleteProductHistory(
          { admin_id: Number(admin_id), history_id },
          {
            onSuccess: () => {
              alert("삭제 성공");
            },
            onError: (error) => {
              alert(returnMutationErrorMessage(error));
            },
            onSettled: () => {
              queryClient.invalidateQueries();
            },
          },
        );
      },
    );
  };

  const onClickEditButton = (item: InOutHistory) => {
    setSelectedHistory(item);
    setBoxQuantity(String(Math.abs(item.box_quantity)));
    setSingleQuantity(String(Math.abs(item.single_quantity)));
    setDate(dayjs(item.created_at).format("YYYY-MM-DD HH:mm"));
    setAddType(undefined); // 수정을 위해 추가 타입을 해제
    setShowEdit(true);
  };

  const onClickAddButton = (type: typeof addType) => {
    setSelectedHistory(undefined);
    setShowEdit(true);
    setAddType(type);
    setBoxQuantity("");
    setSingleQuantity("");
    setDate(dayjs().format("YYYY-MM-DD HH:mm")); // 추가할 때 현재 시간으로 초기화
  };

  const onChangeQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "box") {
      setBoxQuantity(value);
    } else if (name === "single") {
      setSingleQuantity(value);
    } else {
      console.error("INPUT NAME 확인");
    }
  };

  const closeEdit = () => {
    setShowEdit(false);
    setBoxQuantity("");
    setSingleQuantity("");
    setTotalQantity(0);
    setDate(dayjs().format("YYYY-MM-DD HH:mm"));
  };

  const handleSetDate = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDate(dayjs(value).format("YYYY-MM-DD HH:mm"));
  };

  const onClickEditSubmit = () => {
    if (!date) {
      return;
    }

    if (addType === undefined && selectedHistory) {
      const payload: IUpdateMasterAdminProductInoutPayload = {
        admin_id: Number(admin_id),
        history_id: selectedHistory.id,
        box_quantity: Number(boxQuantity),
        date_time: date,
        single_quantity: Number(singleQuantity),
        type: selectedHistory.type,
      };

      updateProductHistory(payload, {
        onSuccess: () => {
          alert("성공");
        },
        onError: (error) => {
          alert(returnMutationErrorMessage(error));
        },
        onSettled: () => {
          queryClient.invalidateQueries();
          closeEdit();
        },
      });
    } else {
      if (!addType || !product_id) {
        console.error("addType 혹은 product_id 없음");
        return;
      }

      const payload: ICreateProductInoutPayload = {
        admin_id: Number(admin_id),
        box_quantity: boxQuantity ? Number(boxQuantity) : 0,
        single_quantity: singleQuantity ? Number(singleQuantity) : 0,
        date_time: date,
        product_id: Number(product_id),
        type: addType,
      };

      console.log("payload", payload);

      createProductHistory(payload, {
        onSuccess: () => {
          alert("성공");
        },
        onError: (error) => {
          alert(returnMutationErrorMessage(error));
        },
        onSettled: () => {
          queryClient.invalidateQueries();
          closeEdit();
        },
      });
    }

    closeEdit();
  };

  const onClickDateRow = () => {
    console.log("눌리긴 해요");
    if (!dateInputRef || !dateInputRef.current) {
      console.log("없어요");
      return;
    }

    dateInputRef.current.showPicker();
  };

  // EFFECTS
  useEffect(() => {
    if ((boxQuantity === "" && singleQuantity === "") || !showEdit) {
      setTotalQantity(0);
    }

    const totalVal = Number(boxQuantity) + Number(singleQuantity);
    setTotalQantity(totalVal);
  }, [boxQuantity, singleQuantity, showEdit]);

  useEffect(() => {
    if (!selectedHistory) {
      setBoxQuantity("");
      setSingleQuantity("");
      setTotalQantity(0);
      setDate(undefined);
    }
  }, [selectedHistory]);

  const returnItem = (
    type: keyof ProductHistoryTableHeader,

    product: ProductInoutResponse,
  ) => {
    switch (type) {
      case "image":
        return <Image key={type} src={product.image as string} alt="product" />;
      case "start_quantity": {
        const { start_box_quantity, unit_quantity, start_single_quantity } =
          product;
        const start_quantity =
          start_box_quantity * unit_quantity + start_single_quantity;
        return <GridItem key={type}>{start_quantity}</GridItem>;
      }
      case "end_quantity": {
        const { end_box_quantity, unit_quantity, end_single_quantity } =
          product;
        const end_quantity =
          (end_box_quantity || 0) * unit_quantity + (end_single_quantity || 0);
        return <GridItem key={type}>{end_quantity}</GridItem>;
      }
      case "missing_quantity": {
        const {
          start_box_quantity,
          start_single_quantity,
          end_box_quantity,
          end_single_quantity,
          unit_quantity,
        } = product;
        const start_quantity =
          start_box_quantity * unit_quantity + start_single_quantity;
        const end_quantity =
          end_box_quantity * unit_quantity + end_single_quantity;
        const missing_quantity = start_quantity - end_quantity;
        return <GridItem key={type}>{missing_quantity}</GridItem>;
      }

      case "carbon": {
        const {
          start_box_quantity,
          start_single_quantity,
          end_box_quantity,
          end_single_quantity,
          unit_quantity,
          carbon_emissions,
        } = product;
        const start_quantity =
          start_box_quantity * unit_quantity + start_single_quantity;
        const end_quantity =
          end_box_quantity * unit_quantity + end_single_quantity;
        const missing_quantity = start_quantity - end_quantity;
        const total_carbon = missing_quantity * carbon_emissions;

        return <GridItem key={type}>{total_carbon}</GridItem>;
      }
      default:
        return <GridItem key={type}>{product[type]}</GridItem>;
    }
  };

  return (
    <Container>
      <GridRow>
        {headers.map((item) => (
          <GridItem key={item.type} $isHeader>
            {item.name}
          </GridItem>
        ))}
        {headers.map((item) => returnItem(item.type, product))}
      </GridRow>
      <InAndOutWrap>
        {/* 출하내역 */}
        <InAndOutContainer>
          <InAndOutTitleWrap>
            <InAndOutTitle>출하 내역</InAndOutTitle>
            <Button
              height={30}
              width={60}
              onClick={() => onClickAddButton("out")}
            >
              추가
            </Button>
          </InAndOutTitleWrap>
          {outbound && outbound.length > 0 && (
            <InAndOutGrid>
              <GridItem $isHeader>날짜</GridItem>
              <GridItem $isHeader>포장 수량</GridItem>
              <GridItem $isHeader>단품 수량</GridItem>
              <GridItem $isHeader>출하량</GridItem>
              <GridItem $isHeader></GridItem>
              <GridItem $isHeader></GridItem>
              {outbound.map((item, i) => (
                <React.Fragment key={i}>
                  <GridItem>
                    {dayjs(item.created_at).format("YYYY-MM-DD HH:mm")}
                  </GridItem>
                  <GridItem>
                    {formatKoreanWon(Math.abs(item.box_quantity))}
                  </GridItem>
                  <GridItem>
                    {formatKoreanWon(Math.abs(item.single_quantity))}
                  </GridItem>
                  <GridItem>
                    {Math.abs(
                      item.box_quantity * product.unit_quantity +
                        item.single_quantity,
                    )}
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => onClickEditButton(item)}
                      width={65}
                      height={30}
                    >
                      수정
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => onClickDelete(item.id)}
                      backgroundColor="red"
                      width={65}
                      height={30}
                    >
                      삭제
                    </Button>
                  </GridItem>
                </React.Fragment>
              ))}
            </InAndOutGrid>
          )}
          {(!outbound || outbound.length === 0) && (
            <InOutText>출고 내역이 없습니다.</InOutText>
          )}
        </InAndOutContainer>
        {/* 회수내역 */}
        <InAndOutContainer>
          <InAndOutTitleWrap>
            <InAndOutTitle>회수 내역</InAndOutTitle>
            <Button
              height={30}
              width={60}
              onClick={() => onClickAddButton("in")}
            >
              추가
            </Button>
          </InAndOutTitleWrap>
          {inbound && inbound.length > 0 && (
            <InAndOutGrid>
              <GridItem $isHeader>날짜</GridItem>
              <GridItem $isHeader>포장 수량</GridItem>
              <GridItem $isHeader>단품 수량</GridItem>
              <GridItem $isHeader>회수량</GridItem>
              <GridItem $isHeader></GridItem>
              <GridItem $isHeader></GridItem>
              {inbound.map((item, i) => (
                <React.Fragment key={i + item.box_quantity}>
                  <GridItem>
                    {dayjs(item.created_at).format("YYYY-MM-DD HH:mm")}
                  </GridItem>
                  <GridItem>
                    {formatKoreanWon(Math.abs(item.box_quantity))}
                  </GridItem>
                  <GridItem>
                    {formatKoreanWon(Math.abs(item.single_quantity))}
                  </GridItem>
                  <GridItem>
                    {Math.abs(
                      item.box_quantity * product.unit_quantity +
                        item.single_quantity,
                    )}
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => onClickEditButton(item)}
                      width={65}
                      height={30}
                    >
                      수정
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => onClickDelete(item.id)}
                      backgroundColor="red"
                      width={65}
                      height={30}
                    >
                      삭제
                    </Button>
                  </GridItem>
                </React.Fragment>
              ))}
            </InAndOutGrid>
          )}
          {(!inbound || inbound.length === 0) && (
            <InOutText>회수 내역이 없습니다.</InOutText>
          )}
        </InAndOutContainer>

        {showEdit && (
          <Modal>
            <EditModalContainer>
              <ModalContent>
                <ModalRow onClick={onClickDateRow}>
                  <h1>날짜 : </h1>
                  <h1>{date}</h1>
                  <DateInput
                    ref={dateInputRef}
                    type="datetime-local"
                    name="date"
                    value={date}
                    onChange={handleSetDate}
                  />
                </ModalRow>
                <ModalRow>
                  <h1>포장 출고/입고 수량</h1>
                  <Input
                    name="box"
                    value={boxQuantity}
                    onChange={onChangeQuantity}
                    type="number"
                  />
                </ModalRow>
                <ModalRow>
                  <h1>단품 출고/입고 수량</h1>
                  <Input
                    name="single"
                    value={singleQuantity}
                    onChange={onChangeQuantity}
                    type="number"
                  />
                </ModalRow>
              </ModalContent>

              <ModalBtnSection>
                <Button onClick={onClickEditSubmit}>
                  {!addType ? "수정" : "추가"}
                </Button>
                <Button onClick={closeEdit} backgroundColor="gray">
                  닫기
                </Button>
              </ModalBtnSection>
            </EditModalContainer>
          </Modal>
        )}
      </InAndOutWrap>
    </Container>
  );
}
