import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  gap: 10px;
`;

export const GridRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-row-gap: 3px;

  grid-column-gap: 3px;
`;

export const GridItem = styled.div<{ $isHeader?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px;

  justify-content: center;
  align-items: center;
  ${({ $isHeader }) => $isHeader && `font-weight:700`};
  background-color: ${({ theme }) => theme.colors.tableBlue};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 50px;
  object-fit: contain;
`;

export const InAndOutWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  margin-top: 20px;
  justify-content: center;
  align-items: flex-start;
`;
export const InAndOutContainer = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 1;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
`;

export const InAndOutTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
`;

export const InAndOutGrid = styled.div`
  width: 600px;
  display: grid;
  // 2024-08-23 LCH : 메모 때문에 컬럼 하나 추가
  grid-template-columns: 2fr 1fr 2fr;
  grid-row-gap: 3px;
  grid-column-gap: 3px;
`;

export const InOutText = styled.p``;
