import { useState } from "react";
import { getAllProductsExcelData } from "../../api/excel.api";
import useAlert from "../../lib/hooks/useAlert";
import usePage from "../../lib/hooks/usePage";
import { productExcel } from "../../lib/utils/excel/product";
import {
  useEditEndQuantity,
  useGetProduct,
} from "../../services/product.service";

export interface EditEndProduct {
  id: number;
  end_box_quantity: number;
  end_single_quantity: number;
  name: string;
}
export const useProductEndQuantity = () => {
  const [modal, setModal] = useState(false);
  const { pageNum, onClickPageHandler } = usePage();
  const { data: products } = useGetProduct(pageNum);
  const [editEndProduct, setEditEndProduct] = useState<EditEndProduct | null>(
    null,
  );

  const handleCloseModal = () => {
    setModal(false);
  };
  const { mutate: editEndQuantityMutate } =
    useEditEndQuantity(handleCloseModal);
  const { showError } = useAlert();

  const handleSubmitEditEndQuantity = () => {
    if (!editEndProduct)
      return showError("수정할 데이터를 불러오지 못했습니다.");
    const { id, end_box_quantity, end_single_quantity } = editEndProduct;

    editEndQuantityMutate({
      productId: id,
      end_box_quantity,
      end_single_quantity,
    });
  };

  const handleClickRow = (payload: EditEndProduct) => {
    setModal(true);
    setEditEndProduct(payload);
  };

  const handleChangeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditEndProduct((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const validateEditState = () => {
    const { end_box_quantity, end_single_quantity } = editEndProduct || {};
    console.log(end_box_quantity, end_single_quantity);
    return Boolean(
      end_box_quantity !== undefined &&
        end_box_quantity >= 0 &&
        end_single_quantity !== undefined &&
        end_single_quantity >= 0,
    );
  };
  console.log(editEndProduct);

  const handleClickExcel = async () => {
    const data = await getAllProductsExcelData();
    return productExcel(data, "end");
  };

  return {
    pageNum,
    products,
    modal,
    onClickPageHandler,
    handleClickRow,
    editEndProduct,
    handleCloseModal,
    handleChangeEdit,
    validateEditState,
    handleSubmitEditEndQuantity,
    handleClickExcel,
  };
};
