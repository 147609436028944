import * as S from "./Login.style";
import logoSrc from "../../assets/images/logo.png";
import userSrc from "../../assets/images/user.png";
import passwordSrc from "../../assets/images/password.png";
import { useLogin } from "../../services/auth.service";
import useLoginHook from "./Login.hook";

export default function Login() {
  const { auth, handleChange, handleSubmit } = useLoginHook();

  return (
    <S.Container>
      <S.LoginBox>
        <S.IconWrapper>
          <S.Logo src={logoSrc} alt="logo" />
          <S.IntroText>담음 관리자 페이지에 오신 것을 환영합니다.</S.IntroText>
        </S.IconWrapper>
        <S.Form onSubmit={handleSubmit}>
          <S.InputWrapper>
            <S.InputIcon src={userSrc} alt="loginLogo" />
            <S.Input
              placeholder="아이디를 입력하여 주세요."
              onChange={handleChange}
              name="id"
              value={auth.id}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputIcon src={passwordSrc} alt="passwordLogo" />
            <S.Input
              placeholder="비밀번호를 입력하여 주세요."
              type="password"
              onChange={handleChange}
              name="password"
              value={auth.password}
            />
          </S.InputWrapper>
          <S.Button>로그인</S.Button>
        </S.Form>
      </S.LoginBox>
    </S.Container>
  );
}
