import * as S from "./StuffList.style";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import { useStuff } from "../../services/stuff.service";
import { useStuffList } from "./StuffList.hook";
import Pagination from "../../components/common/pagination/Pagination";
import { ServerStuff } from "../../types/stuff.type";
import Modal from "../../components/common/modal/Modal";
import Button from "../../components/common/button/Button";

const productColumns: { name: string; type: keyof ServerStuff }[] = [
  {
    name: "제품명",
    type: "name",
  },
  {
    name: "이미지",
    type: "image",
  },
  {
    name: "규격",
    type: "size",
  },
  {
    name: "포장수량",
    type: "unit_quantity",
  },
  {
    name: "탄소배출량/ea",
    type: "carbon_emissions",
  },
  {
    name: "단위",
    type: "carbon_unit",
  },
  {
    name: "비고",
    type: "memo",
  },
];

export default function StuffList() {
  const {
    handleNavigateToAdd,
    editModal,
    selectedStuff,
    handleCloseModal,
    handleEditStuff,
    stuffList,
    pageNum,
    onClickPageHandler,
    editState,
    handleEditChange,
    imagePreview,
    imageRef,
    handleImageChange,
    handleClickImage,
    validateStuff,
    handleSubmit,
    handleDeleteProduct,
    handleClickExcel,
  } = useStuffList();
  console.log(stuffList);

  const renderItem = (type: keyof ServerStuff, stuff: ServerStuff) => {
    switch (type) {
      case "image":
        return <S.Image key={type} src={stuff.image} alt="stuff" />;
      default:
        return <S.GridItem key={type}>{stuff[type]}</S.GridItem>;
    }
  };

  return (
    <PageLayout
      title="부자재 리스트"
      showAdd
      showExcel
      addFunction={handleNavigateToAdd}
      getExcelFunction={handleClickExcel}
    >
      <S.Container>
        <S.GridRow $isHeader>
          {productColumns.map((item, i) => (
            <S.GridItem key={i} $isHeader>
              {item.name}
            </S.GridItem>
          ))}
        </S.GridRow>
        {stuffList?.items.map((stuff, i) => (
          <S.GridRow key={i} onClick={() => handleEditStuff(stuff)}>
            {productColumns.map((item, j) => renderItem(item.type, stuff))}
          </S.GridRow>
        ))}
        <S.PaginationLayout>
          <Pagination
            currentPage={pageNum}
            totalPages={stuffList?.totalPages || 5}
            size={5}
            onClickPage={onClickPageHandler}
          />
        </S.PaginationLayout>
      </S.Container>
      {editModal && (
        <Modal closeModal={handleCloseModal}>
          <S.EditModalContainer>
            {productColumns.map((item, i) => {
              if (item.type === "image") return;
              return (
                <S.ModalInputWrapper key={i}>
                  <S.InputLabel>{item.name}</S.InputLabel>
                  <S.Input
                    value={editState && editState[item.type]!}
                    type={
                      item.type === "carbon_emissions" ||
                      item.type === "unit_quantity"
                        ? "number"
                        : "text"
                    }
                    name={item.type}
                    onChange={handleEditChange}
                  />
                </S.ModalInputWrapper>
              );
            })}
            <S.ModalInputWrapper>
              <S.InputLabel>이미지</S.InputLabel>
              <S.Input
                ref={imageRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <S.Image
                onClick={handleClickImage}
                src={imagePreview ? imagePreview : editState?.image || ""}
                alt="프리뷰"
              />
            </S.ModalInputWrapper>
            <S.ButtonWrap>
              <Button disabled={!validateStuff()} onClick={handleSubmit}>
                수정
              </Button>
              <Button backgroundColor="red" onClick={handleDeleteProduct}>
                삭제
              </Button>
              <Button backgroundColor="gray" onClick={handleCloseModal}>
                닫기
              </Button>
            </S.ButtonWrap>
          </S.EditModalContainer>
        </Modal>
      )}
    </PageLayout>
  );
}
