import api from "./axios";

export const getProductHistoriesExcelData = async () => {
  const { data } = await api.get("/excel/product/history");
  return data.data;
};

export const getStuffHistoriesExcelData = async () => {
  const { data } = await api.get("/excel/stuff/history");
  return data.data;
};

export const getAllProductsExcelData = async () => {
  const { data } = await api.get("/excel/product");
  return data.data;
};

export const getAllStuffExcelData = async () => {
  const { data } = await api.get("/excel/stuff");
  return data.data;
};
