import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  gap: 30px;
  overflow-y: auto;
`;

export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const NameContainer = styled.div`
  width: 400px;
  display: flex;
  gap: 20px;
  align-items: center;
  /* background-color: blue; */
  svg {
    cursor: pointer;
  }
`;

export const Label = styled.p`
  font-size: 24px;
`;

export const Name = styled.p`
  font-size: 20px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const NameInput = styled.input`
  font-size: 20px;
  display: flex;
  flex-grow: 1;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

export const ImageList = styled.div`
  display: flex;
  gap: 50px;
`;

export const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  border-radius: 10px;
  /* overflow: hidden; */
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const Delete = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 15px;
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.red};
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
`;

export const ImageInput = styled.input`
  display: none;
`;

export const ImageUpload = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
