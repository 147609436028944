import { isAxiosError } from "axios";

const returnMutationErrorMessage = (error: Error | unknown) => {
  let errorMessage = "에러 발생";
  if (isAxiosError(error) && error.response?.data.message) {
    errorMessage = error.response.data.message;
  }
  return errorMessage;
};

export default returnMutationErrorMessage;
