import dayjs from "dayjs";
import React from "react";
import { start } from "repl";
import {
  InOutHistory,
  ProductHistoryTableHeader,
  ProductInoutResponse,
} from "../../../types/products";
import * as S from "./ProductHistoryTable.style";

const headers: { type: keyof ProductHistoryTableHeader; name: string }[] = [
  { type: "name", name: "제품명" },
  { type: "image", name: "이미지" },
  { type: "size", name: "규격" },
  { type: "carbon_emissions", name: "탄소배출량/ea" },
  { type: "carbon_unit", name: "단위" },
  { type: "unit_quantity", name: "포장 수량" },
  { type: "start_quantity", name: "시작재고 수량" },
  { type: "end_quantity", name: "종료재고 수량" },
  { type: "missing_quantity", name: "분실,파손 수량" },
  { type: "carbon", name: "탄소량" },
];

interface ProductHistoryTableProps {
  product: ProductInoutResponse;
}
export default function ProductHistoryTable({
  product,
}: ProductHistoryTableProps) {
  const { name } = product;

  const outbound = product.outbound_histories;
  const totalCnt = (histories: InOutHistory[], unit_quantity: number) => {
    return histories.reduce((acc, cur) => {
      const currentTotal =
        Math.abs(cur.box_quantity * unit_quantity) +
        Math.abs(cur.single_quantity);
      return acc + currentTotal;
    }, 0);
  };

  const inbound = product.inbound_histories;

  const returnItem = (
    type: keyof ProductHistoryTableHeader,

    product: ProductInoutResponse,
  ) => {
    switch (type) {
      case "image":
        return (
          <S.Image key={type} src={product.image as string} alt="product" />
        );
      case "start_quantity": {
        const { start_box_quantity, unit_quantity, start_single_quantity } =
          product;
        const start_quantity =
          start_box_quantity * unit_quantity + start_single_quantity;
        return <S.GridItem key={type}>{start_quantity}</S.GridItem>;
      }
      case "end_quantity": {
        const { end_box_quantity, unit_quantity, end_single_quantity } =
          product;
        const end_quantity =
          (end_box_quantity || 0) * unit_quantity + (end_single_quantity || 0);
        return <S.GridItem key={type}>{end_quantity}</S.GridItem>;
      }

      case "missing_quantity": {
        const {
          start_box_quantity,
          start_single_quantity,
          end_box_quantity,
          end_single_quantity,
          unit_quantity,
        } = product;
        const start_quantity =
          start_box_quantity * unit_quantity + start_single_quantity;
        const end_quantity =
          end_box_quantity * unit_quantity + end_single_quantity;
        const missing_quantity = start_quantity - end_quantity;
        return <S.GridItem key={type}>{missing_quantity}</S.GridItem>;
      }

      case "carbon": {
        const {
          start_box_quantity,
          start_single_quantity,
          end_box_quantity,
          end_single_quantity,
          unit_quantity,
          carbon_emissions,
        } = product;
        const start_quantity =
          start_box_quantity * unit_quantity + start_single_quantity;
        const end_quantity =
          end_box_quantity * unit_quantity + end_single_quantity;
        const missing_quantity = start_quantity - end_quantity;
        const total_carbon = missing_quantity * carbon_emissions;

        return <S.GridItem key={type}>{total_carbon}</S.GridItem>;
      }
      default:
        return <S.GridItem key={type}>{product[type]}</S.GridItem>;
    }
  };

  return (
    <S.Container>
      <S.GridRow>
        {headers.map((item) => (
          <S.GridItem key={item.type} $isHeader>
            {item.name}
          </S.GridItem>
        ))}
        {headers.map((item) => returnItem(item.type, product))}
      </S.GridRow>
      <S.InAndOutWrap>
        {/* 출하내역 */}
        <S.InAndOutContainer>
          <S.InAndOutTitle>출하 내역</S.InAndOutTitle>
          {outbound && outbound.length > 0 && (
            <S.InOutText>
              총 수량 : {totalCnt(outbound, product.unit_quantity)}
            </S.InOutText>
          )}
          {outbound && outbound.length > 0 && (
            <S.InAndOutGrid>
              <S.GridItem $isHeader>날짜</S.GridItem>
              <S.GridItem $isHeader>출하량</S.GridItem>
              <S.GridItem $isHeader>메모</S.GridItem>
              {outbound.map((item, i) => (
                <React.Fragment key={i}>
                  <S.GridItem>
                    {dayjs(item.created_at).format("YYYY-MM-DD HH:mm")}
                  </S.GridItem>
                  <S.GridItem>
                    {Math.abs(item.box_quantity * product.unit_quantity) +
                      Math.abs(item.single_quantity)}
                  </S.GridItem>
                  <S.GridItem>{item.history_memo}</S.GridItem>
                </React.Fragment>
              ))}
            </S.InAndOutGrid>
          )}

          {(!outbound || outbound.length === 0) && (
            <S.InOutText>출고 내역이 없습니다.</S.InOutText>
          )}
        </S.InAndOutContainer>
        {/* 회수내역 */}
        <S.InAndOutContainer>
          <S.InAndOutTitle>회수 내역</S.InAndOutTitle>
          {inbound && inbound.length > 0 && (
            <S.InOutText>
              총 수량 : {totalCnt(inbound, product.unit_quantity)}
            </S.InOutText>
          )}
          {inbound && inbound.length > 0 && (
            <S.InAndOutGrid>
              <S.GridItem $isHeader>날짜</S.GridItem>
              <S.GridItem $isHeader>회수량</S.GridItem>
              <S.GridItem $isHeader>메모</S.GridItem>
              {inbound.map((item, i) => (
                <React.Fragment key={i}>
                  <S.GridItem>
                    {dayjs(item.created_at).format("YYYY-MM-DD HH:mm")}
                  </S.GridItem>
                  <S.GridItem>
                    {item.box_quantity * product.unit_quantity +
                      item.single_quantity}
                  </S.GridItem>
                  <S.GridItem>{item.history_memo}</S.GridItem>
                </React.Fragment>
              ))}
            </S.InAndOutGrid>
          )}
          {(!inbound || inbound.length === 0) && (
            <S.InOutText>회수 내역이 없습니다.</S.InOutText>
          )}
        </S.InAndOutContainer>
      </S.InAndOutWrap>
    </S.Container>
  );
}
