import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAdmin, IGetAdminProductInoutPayload } from "../types/master.types";
import {
  createMasterAdminProductInout,
  deleteMasterAdminProduct,
  deleteMasterAdminProductInout,
  deleteMasterAdminStuff,
  getAdmins,
  getMasterAdminProductInout,
  getMasterAdminProducts,
  getMasterAdminStuffs,
  updateMasterAdminProduct,
  updateMasterAdminProductInout,
  updateMasterAdminStuff,
} from "../api/master.api";

export const GET_ADMIN_LIST_QUERY_KEY = ["getAdminList"];
export const GET_ADMIN_PRODUCTS_QUERY_KEY = ["getMasterAdminProducts"];
export const GET_ADMIN_PRODUCTS_INOUT_QUERY_KEY = ["getAdminProductInout"];
export const GET_ADMIN_STUFFS_QUERY_KEY = ["getMasterAdminStuffs"];

export const useAdminList = (pageNum: number, perPage?: number) => {
  const [adminListData, setAdminListData] = useState<IAdmin[]>([]);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [...GET_ADMIN_LIST_QUERY_KEY, pageNum, perPage],
    queryFn: () => getAdmins({ pageNum, perPage }),
    enabled: localStorage.getItem("type") === "master",
  });

  useEffect(() => {
    if (isError) {
      setAdminListData([]);
    }
    if (isLoading || !data) {
      return;
    }

    if (data === undefined) {
      setAdminListData([]);
    }

    setAdminListData([...data.items]);
  }, [isLoading, isError]);

  return { adminListData, isLoading, isError, refetch };
};

// 마스터 어드민의 제품 조회
export const useGetAdminProducts = (
  adminId: number,
  pageNum: number,
  enabled: boolean,
  perPage?: number,
) => {
  return useQuery({
    queryKey: [...GET_ADMIN_PRODUCTS_QUERY_KEY, adminId, pageNum, perPage],
    queryFn: () => getMasterAdminProducts(adminId, pageNum, perPage),
    enabled,
  });
};

// 마스터 어드민의 부자재 조회
export const useGetAdminStuffs = (
  adminId: number,
  pageNum: number,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [...GET_ADMIN_STUFFS_QUERY_KEY, adminId, pageNum],
    queryFn: () => getMasterAdminStuffs(adminId, pageNum),
    enabled,
  });
};

// 마스터 어드민 제품 삭제
export const useDeleteMasterAdminProduct = () => {
  const { mutate } = useMutation({
    //@ts-ignore
    mutationFn: deleteMasterAdminProduct,
  });

  return mutate;
};

// 마스터 어드민 부자재 삭제
export const useDeleteMasterAdminStuff = () => {
  const { mutate } = useMutation({
    //@ts-ignore
    mutationFn: deleteMasterAdminStuff,
  });

  return mutate;
};

// 마스터 어드민 제품 수정
export const useUpdateMasterAdminProduct = () => {
  const { mutate } = useMutation({
    // @ts-ignore
    mutationFn: updateMasterAdminProduct,
  });

  return mutate;
};

// 마스터 어드민 부자재 수정
export const useUpdateMasterAdminStuff = () => {
  const { mutate } = useMutation({
    // @ts-ignore
    mutationFn: updateMasterAdminStuff,
  });

  return mutate;
};

// 마스터 제품 입출고 내역 조회
export const useGetMasterAdminProductInout = (
  query: IGetAdminProductInoutPayload,
) => {
  return useQuery({
    queryKey: [...GET_ADMIN_PRODUCTS_INOUT_QUERY_KEY, { ...query }],
    queryFn: () => getMasterAdminProductInout(query),
  });
};

// 마스터 어드민 제품 입출고 내역 삭제
export const useDeleteMasterAdminProductInout = () => {
  const { mutate } = useMutation({
    mutationFn: deleteMasterAdminProductInout,
  });

  return mutate;
};

// 마스터 어드민 제품 입출고 내역 업데이트
export const useUpdateMasterAdminProductInout = () => {
  const { mutate } = useMutation({
    mutationFn: updateMasterAdminProductInout,
  });

  return mutate;
};

// 마스터 어드민 제품 입출고 등록
export const useCreateMasterAdminProductInout = () => {
  const { mutate } = useMutation({
    mutationFn: createMasterAdminProductInout,
  });

  return mutate;
};
