import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProductHistoriesExcelData } from "../../api/excel.api";
import { useAllProducts, useInOutData } from "../../services/product.service";
import { generateCSVData } from "../Test/ExportCSV";

import * as XLSX from "xlsx";
import { generateExcelData } from "../../lib/utils/generateExcelData";
import { productHistoriesExcel } from "../../lib/utils/excel/productHistories";

export default function useProductHistory() {
  const endDate = dayjs().format("YYYY-MM-DD");
  const firstDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const [selectProductId, setSelectProductId] = useState(0);
  const [date, setDate] = useState({
    firstDate: firstDate || "",
    endDate: endDate || "",
  });
  const firstDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);

  const { data: inOutData } = useInOutData({
    ...date,
    productId: selectProductId,
  });

  const { data: allProduct } = useAllProducts();
  console.log(allProduct);

  console.log(inOutData);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectProductId(parseInt(value));
  };

  const handleSetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDate((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickDate = (type: "firstDate" | "endDate") => {
    if (type === "firstDate") {
      firstDateRef.current?.showPicker();
      console.log("hi");
      return;
    }
    if (type === "endDate") {
      endDateRef.current?.showPicker();
      return;
    }
  };

  const handleClickExcel = async () => {
    console.log("클릭!");

    //원본 데이터 페칭
    const data = await getProductHistoriesExcelData();
    return productHistoriesExcel(data);
  };

  return {
    date,
    handleSetDate,
    firstDateRef,
    endDateRef,
    handleClickDate,
    inOutData,
    allProduct,
    selectProductId,
    handleSelectChange,
    handleClickExcel,
  };
}
