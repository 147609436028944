import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AddMasterStuffHistoryPayload } from "../../../api/masterStuff.api";
import useAlert from "../../../lib/hooks/useAlert";
import {
  useMasterAddStuffHistory,
  useMasterDeleteStuffHistory,
  useMasterEditStuffHistory,
} from "../../../services/masterStuff.service";
import { StuffHistory } from "../../../types/stuff.type";

const initialAddState = {
  stuff_id: 0,
  box_quantity: 0,
  single_quantity: 0,
  date_time: "",
};

export const useMasterStuffHistoryTableHook = () => {
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addState, setAddState] =
    useState<AddMasterStuffHistoryPayload>(initialAddState);
  const [editHistory, setEditHistory] = useState<StuffHistory | null>();
  const dateRef = useRef<HTMLInputElement>(null);
  const { admin_id, stuff_id } = useParams();
  console.log(admin_id, stuff_id);
  const { showError, showConfirmation } = useAlert();

  const handleCloseModal = () => {
    setModal(false);
  };
  const handleCloseAddModal = () => {
    setAddModal(false);
  };
  const { mutate: addMutate } = useMasterAddStuffHistory(handleCloseAddModal);
  const { mutate: deleteMutate } = useMasterDeleteStuffHistory();
  const { mutate: editMutate } = useMasterEditStuffHistory(handleCloseModal);

  const handleEditSubmit = () => {
    if (!editHistory) return;
    const { id, created_at, box_quantity, single_quantity } = editHistory;
    if (!validateEditHistory(editHistory)) {
      showError("입력값을 다시 확인해주세요");
      return;
    }
    editMutate({
      adminId: admin_id!,
      historyId: id,
      payload: {
        box_quantity,
        single_quantity,
        date_time: dayjs(created_at).format("YYYY-MM-DD"),
      },
    });
  };

  const handleAddSubmit = () => {
    if (!validateAddHistory(addState)) {
      showError("입력값을 다시 확인해주세요");
      return;
    }
    addMutate({ adminId: admin_id!, payload: addState });
  };

  const handleAddChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddState((prev) => ({ ...prev, [name]: value }));
  };

  const handleDelete = (id: number) => {
    showConfirmation("삭제", "해당 수정내역을 삭제하시겠습니까?", () =>
      deleteMutate({ adminId: admin_id!, historyId: id }),
    );
  };

  const handlePickDate = () => {
    console.log("클릭");
    dateRef.current?.showPicker();
  };

  const handleOpenAddModal = () => {
    setAddModal(true);
    setAddState((prev) => ({ ...prev, stuff_id: parseInt(stuff_id || "0") }));
  };

  const handleOpenEditModal = (history: StuffHistory) => {
    setEditHistory(history);
    setModal(true);
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditHistory((prev) => {
      if (!prev) return prev; // 이전 상태가 없으면 그대로 반환

      return {
        ...prev,
        [name]: value, // 필요한 필드를 업데이트
      };
    });
  };

  const validateAddHistory = (addHistory: AddMasterStuffHistoryPayload) => {
    const { single_quantity, stuff_id, date_time, box_quantity } = addHistory;
    if (
      single_quantity === null ||
      single_quantity === undefined ||
      single_quantity < 0
    ) {
      return false;
    }

    if (!stuff_id) {
      return false;
    }

    if (date_time.trim() === "" || !date_time) {
      return false;
    }

    if (
      box_quantity === null ||
      box_quantity === undefined ||
      box_quantity < 0
    ) {
      return false;
    }
    return true;
  };

  const validateEditHistory = (editHistory: StuffHistory): boolean => {
    const { box_quantity, single_quantity, created_at } = editHistory;

    // box_quantity와 single_quantity는 0 이상이어야 하고, null이나 undefined가 아니어야 함
    if (
      box_quantity === null ||
      box_quantity === undefined ||
      box_quantity < 0
    ) {
      console.error("Invalid box_quantity. It must be a non-negative number.");
      return false;
    }

    if (
      single_quantity === null ||
      single_quantity === undefined ||
      single_quantity < 0
    ) {
      console.error(
        "Invalid single_quantity. It must be a non-negative number.",
      );
      return false;
    }

    // date_time은 공백이 아닌 값이어야 함
    if (typeof created_at !== "string" || created_at.trim() === "") {
      console.error("Invalid date_time. It cannot be empty.");
      return false;
    }

    return true;
  };

  return {
    modal,
    handleCloseModal,
    handleOpenEditModal,
    editHistory,
    handleEditChange,
    dateRef,
    handlePickDate,
    handleEditSubmit,
    handleDelete,
    addModal,
    handleOpenAddModal,
    handleCloseAddModal,
    handleAddChange,
    handleAddSubmit,
    addState,
    validateAddHistory,
  };
};
