import React from "react";
import { CSVLink } from "react-csv";

const apiData = [
  {
    id: 68,
    name: "이상해씨",
    admin_id: 2,
    image:
      "https://renew-dameum.s3.ap-northeast-2.amazonaws.com/이상해씨-1724202723664",
    size: "30 * 50 * 30",
    unit_quantity: 600,
    carbon_emissions: 0,
    carbon_unit: "0",
    memo: "비고입력",
    created_at: "2024-08-23T07:12:00.000Z",
    start_box_quantity: 10,
    start_single_quantity: 100,
    end_box_quantity: 0,
    end_single_quantity: 0,
    missing_quantity: 0,
    carbon: null,
    histories: [
      {
        id: 68,
        box_quantity: 2,
        single_quantity: 0,
        type: "in",
        history_memo: "",
        created_at: "2024-08-23T07:12:00.000Z",
      },
      {
        id: 999,
        box_quantity: 1,
        single_quantity: 0,
        type: "in",
        history_memo: "",
        created_at: "2024-08-20T07:12:00.000Z",
      },
      {
        id: 65,
        box_quantity: -1,
        single_quantity: 0,
        type: "out",
        history_memo: "",
        created_at: "2024-08-23T07:11:00.000Z",
      },
      {
        id: 66,
        box_quantity: -1,
        single_quantity: 0,
        type: "out",
        history_memo: "",
        created_at: "2024-08-23T07:11:00.000Z",
      },
    ],
  },
  {
    id: 72,
    name: "신라면",
    admin_id: 2,
    image:
      "https://cdn.kyongbuk.co.kr/news/photo/201408/167268_20140805048.jpg",
    size: "사이즈",
    unit_quantity: 30,
    carbon_emissions: 3.7,
    carbon_unit: "37/kg",
    memo: null,
    created_at: "2024-08-23T07:12:00.000Z",
    start_box_quantity: 0,
    start_single_quantity: 0,
    end_box_quantity: 0,
    end_single_quantity: 0,
    missing_quantity: 0,
    carbon: null,
    histories: [
      {
        id: 72,
        box_quantity: 1,
        single_quantity: 0,
        type: "in",
        history_memo: "",
        created_at: "2024-08-23T07:12:00.000Z",
      },

      {
        id: 73,
        box_quantity: -20,
        single_quantity: 0,
        type: "out",
        history_memo: "",
        created_at: "2024-08-20T06:49:00.000Z",
      },
      {
        id: 74,
        box_quantity: 104,
        single_quantity: 0,
        type: "in",
        history_memo: "",
        created_at: "2024-08-20T06:49:00.000Z",
      },
    ],
  },
  {
    id: 78,
    name: "사파이어",
    admin_id: 2,
    image: "https://fivenplus.hgodo.com//categori_img/sapphire.png",
    size: "사이즈",
    unit_quantity: 30,
    carbon_emissions: 3.7,
    carbon_unit: "37/kg",
    memo: "메모다",
    created_at: "2024-08-19T06:49:00.000Z",
    start_box_quantity: 0,
    start_single_quantity: 0,
    end_box_quantity: 0,
    end_single_quantity: 0,
    missing_quantity: 0,
    carbon: null,
    histories: [
      {
        id: 78,
        box_quantity: -1,
        single_quantity: -20,
        type: "out",
        history_memo: "",
        created_at: "2024-08-19T06:49:00.000Z",
      },
      {
        id: 79,
        box_quantity: 20,
        single_quantity: 1,
        type: "in",
        history_memo: "",
        created_at: "2024-08-19T06:49:00.000Z",
      },
    ],
  },
];

export const generateCSVData = (apiData) => {
  // Extract all unique dates from history
  const uniqueDates = Array.from(
    new Set(
      apiData.flatMap((item) =>
        item.histories.map((history) => history.created_at.split("T")[0]),
      ),
    ),
  ).sort(); // 날짜를 오름차순으로 정렬

  // Create CSV rows
  const rows = [];
  const headers = [
    "제품명",
    "규격",
    "탄소배출량/ea",
    "단위",
    "포장수량",
    "시작재고 수량",
    "종료재고 수량",
    "분실파손 수량",
    "탄소량",
  ];

  // Find the maximum number of actions per date
  const maxActionsPerDate = uniqueDates.reduce((acc, date) => {
    const actionsCount = Math.max(
      ...apiData.map(
        (product) =>
          product.histories.filter((history) =>
            history.created_at.startsWith(date),
          ).length,
      ),
    );
    acc[date] = actionsCount;
    return acc;
  }, {});

  // Add dynamic headers based on dates
  uniqueDates.forEach((date) => {
    headers.push(`${date}`); // 날짜는 한 번만 추가
    for (let i = 0; i < maxActionsPerDate[date]; i++) {
      headers.push("");
    }
  });

  // Loop through each product and create rows
  apiData.forEach((product) => {
    const row = [
      product.name,
      product.size,
      product.carbon_emissions,
      product.carbon_unit,
      product.unit_quantity,
      product.start_box_quantity,
      product.end_box_quantity,
      product.missing_quantity,
      product.carbon || 0,
    ];

    // Add date-specific data
    uniqueDates.forEach((date) => {
      const dateData = product.histories.filter((entry) =>
        entry.created_at.startsWith(date),
      );

      // Add the actions to the row, and fill with empty strings if necessary
      dateData.forEach((entry) => {
        row.push(
          `${entry.type === "in" ? "입고" : "출고"}\n${Math.abs(
            entry.box_quantity,
          )}`,
        );
      });

      // Fill the remaining cells with empty strings if there are fewer actions than the max for this date
      for (let i = dateData.length; i < maxActionsPerDate[date]; i++) {
        row.push("");
      }
    });

    // Push the row to the rows array
    rows.push(row);
  });

  return [headers, ...rows];
};

const ExportCSV = () => {
  const csvData = generateCSVData(apiData);

  return (
    <CSVLink data={csvData} filename={"product_histories.csv"}>
      Download CSV
    </CSVLink>
  );
};

export default ExportCSV;
