import React, { useState } from "react";
import { LoginPayload } from "../../api/auth.api";
import useAlert from "../../lib/hooks/useAlert";
import { useLogin } from "../../services/auth.service";

const initialState = {
  id: "",
  password: "",
};

export default function useLoginHook() {
  const [auth, setAuth] = useState<LoginPayload>(initialState);
  const { mutate: loginMutate } = useLogin();
  const { showError } = useAlert();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAuth((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (auth.id.trim() === "" || auth.password.trim() === "") {
      showError("아이디와 비밀번호를 모두 입력해주세요.");
      return;
    }
    loginMutate(auth);
  };

  return { auth, handleChange, handleSubmit };
}
