import * as S from "./ProductEndQuantity.style";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import { ServerProduct } from "../../types/products";
import Pagination from "../../components/common/pagination/Pagination";
import { useProductEndQuantity } from "./ProductEndQuantity.hook";
import { safeParseAndFormat } from "../../lib/utils/formatSafeNumber";
import Button from "../../components/common/button/Button";
import Modal from "../../components/common/modal/Modal";
type RenderType = keyof ServerProduct | "end_total_quantity";
const productColumns: { name: string; type: RenderType }[] = [
  {
    name: "제품명",
    type: "name",
  },
  {
    name: "이미지",
    type: "image",
  },
  {
    name: "규격",
    type: "size",
  },
  {
    name: "포장수량",
    type: "unit_quantity",
  },
  {
    name: "비고",
    type: "memo",
  },
  {
    name: "포장 수량",
    type: "end_box_quantity",
  },
  {
    name: "단품 수량",
    type: "end_single_quantity",
  },
  {
    name: "총 수량",
    type: "end_total_quantity",
  },
];

export default function ProductEndQuantity() {
  const {
    pageNum,
    products,
    modal,
    onClickPageHandler,
    handleClickRow,
    editEndProduct,
    handleCloseModal,
    handleChangeEdit,
    validateEditState,
    handleSubmitEditEndQuantity,
    handleClickExcel,
  } = useProductEndQuantity();

  const renderItem = (type: RenderType, product: ServerProduct) => {
    switch (type) {
      case "image":
        return <S.Image key={type} src={product.image} alt="product" />;
      case "end_total_quantity": {
        const { end_box_quantity, end_single_quantity, unit_quantity } =
          product;
        const total_quantity =
          end_box_quantity * unit_quantity + end_single_quantity;
        return (
          <S.GridItem key={type}>
            {safeParseAndFormat(total_quantity)}
          </S.GridItem>
        );
      }
      case "unit_quantity":
        return (
          <S.GridItem key={type}>
            {safeParseAndFormat(product.unit_quantity)}
          </S.GridItem>
        );
      default:
        return <S.GridItem key={type}>{product[type]}</S.GridItem>;
    }
  };

  return (
    <PageLayout
      title="종료재고 수량"
      showExcel
      getExcelFunction={handleClickExcel}
    >
      <S.Container>
        <S.GridRow $isHeader>
          {productColumns.map((item, i) => (
            <S.GridItem key={i} $isHeader>
              {item.name}
            </S.GridItem>
          ))}
          {/* <S.GridItem></S.GridItem> */}
        </S.GridRow>
        {products?.items.map((product, i) => (
          <S.GridRow
            key={i}
            onClick={() =>
              handleClickRow({
                id: product.id,
                end_box_quantity: product.end_box_quantity,
                end_single_quantity: product.end_single_quantity,
                name: product.name,
              })
            }
          >
            {productColumns.map((item, j) => renderItem(item.type, product))}
          </S.GridRow>
        ))}
        <S.PaginationLayout>
          <Pagination
            currentPage={pageNum}
            totalPages={products?.totalPages || 5}
            size={5}
            onClickPage={onClickPageHandler}
          />
        </S.PaginationLayout>
      </S.Container>
      {modal && editEndProduct && (
        <Modal>
          <S.ModalContainer>
            <S.ModalTitle>{editEndProduct.name} 종료 재고</S.ModalTitle>
            <S.InputWrap>
              <S.InputLabel>포장 수량</S.InputLabel>
              <S.Input
                name="end_box_quantity"
                value={editEndProduct.end_box_quantity}
                onChange={handleChangeEdit}
                type="number"
              />
            </S.InputWrap>
            <S.InputWrap>
              <S.InputLabel>단품 수량</S.InputLabel>
              <S.Input
                name="end_single_quantity"
                value={editEndProduct.end_single_quantity}
                onChange={handleChangeEdit}
                type="number"
              />
            </S.InputWrap>
            <S.ButtonWrap>
              <Button
                disabled={!validateEditState()}
                onClick={handleSubmitEditEndQuantity}
              >
                수정
              </Button>
              <Button backgroundColor="lightBlue" onClick={handleCloseModal}>
                닫기
              </Button>
            </S.ButtonWrap>
          </S.ModalContainer>
        </Modal>
      )}
    </PageLayout>
  );
}
