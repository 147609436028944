import { styled } from "styled-components";
import { theme } from "../../../theme/theme";

export const Container = styled.button<{
  $width: number;
  $height: number;
  $backgroundColor: keyof typeof theme.colors;
  $fontColor: keyof typeof theme.colors;
  $border: boolean;
  $borderColor: keyof typeof theme.colors;
  $disabled: boolean;
  $fontWeight: number;
}>`
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor]};
  color: ${({ $fontColor, theme }) => theme.colors[$fontColor]};
  ${({ $border, $borderColor, theme }) =>
    $border && `border:1px solid ${theme.colors[$borderColor]}`};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  cursor: ${({ $disabled }) =>
    $disabled ? "not-allowed" : "pointer"}; // disabled일 때 커서를 변경
  opacity: ${({ $disabled }) =>
    $disabled ? 0.6 : 1}; // disabled일 때 불투명도 적용
  pointer-events: ${({ $disabled }) =>
    $disabled ? "none" : "auto"}; // disabled일 때 클릭 비활성화
`;
