import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../lib/hooks/useAlert";
import { useAddStuff } from "../../services/stuff.service";
import { ServerStuff, StuffForm } from "../../types/stuff.type";

const initialState: StuffForm = {
  name: "",
  image: null,
  size: "",
  unit_quantity: 0,
  carbon_emissions: 0,
  carbon_unit: "",
  memo: "",
};

export const useStuffAddHook = () => {
  const { admin_id: admin_id_from_master } = useParams();
  const [stuff, setStuff] = useState(initialState);
  const [imagePreview, setImagePreview] = useState<string | null>();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const { showError } = useAlert();

  const { mutate: addMutate } = useAddStuff();

  const handleSubmit = () => {
    if (!validateProduct()) {
      showError("비고를 제외하고 모두 입력해야 합니다.");
    }
    const formData = new FormData();
    formData.append("name", stuff.name);
    formData.append("size", stuff.size);
    formData.append("unit_quantity", stuff.unit_quantity.toString());
    formData.append("carbon_emissions", stuff.carbon_emissions.toString());
    formData.append("carbon_unit", stuff.carbon_unit);
    formData.append("memo", stuff.memo || "");
    if (admin_id_from_master) {
      formData.append("admin_id_from_master", admin_id_from_master);
    }

    if (stuff.image) {
      formData.append("image", stuff.image);
    }

    addMutate(formData);
  };

  const handleChangeStuff = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStuff((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setStuff((prev) => ({ ...prev, image: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClickImageInput = () => {
    imageInputRef.current?.click();
  };

  const validateProduct = () => {
    const { name, image, size, unit_quantity, carbon_emissions, carbon_unit } =
      stuff;
    return Boolean(
      name.trim() && // 이름이 존재하고 공백이 아닌지 확인
        size.trim() && // 사이즈가 존재하고 공백이 아닌지 확인
        unit_quantity >= 0 && // 수량이 0보다 큰지 확인
        carbon_emissions >= 0 && // 탄소 배출량이 0보다 큰지 확인
        carbon_unit.trim() && // 단위가 존재하고 공백이 아닌지 확인
        image, // 이미지가 존재하는지 확인
    );
  };
  return {
    stuff,
    handleChangeStuff,
    handleChangeImage,
    imagePreview,
    imageInputRef,
    handleClickImageInput,
    validateProduct,
    handleSubmit,
  };
};
