import * as S from "./Setting.style";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import { useSettingHook } from "./Setting.hook";
import { ReactComponent as EditIcon } from "../../assets/svgs/edit.svg";
import { ReactComponent as SaveIcon } from "../../assets/svgs/save.svg";
import { ReactComponent as CancelIcon } from "../../assets/svgs/Cancel.svg";

const imageArr = ["image_1", "image_2", "image_3", "image_4", "image_5"];

export default function Setting() {
  const {
    adminData,
    editName,
    handleNameChange,
    editNameMode,
    handleOnEditName,
    handleOffEditName,
    setImageInputRef,
    handleUploadClick,
    handleImageChange,
    handleDeleteImage,
    handleSubmitName,
  } = useSettingHook();

  console.log(adminData);
  return (
    <PageLayout title="기업 정보 변경">
      <S.Container>
        <S.InfoWrap>
          <S.Label>기업 이름</S.Label>
          {editNameMode ? (
            <S.NameContainer>
              <S.NameInput value={editName} onChange={handleNameChange} />
              <S.ButtonWrap>
                <SaveIcon onClick={handleSubmitName} />
                <CancelIcon onClick={handleOffEditName} />
              </S.ButtonWrap>
            </S.NameContainer>
          ) : (
            <S.NameContainer>
              <S.Name>{(adminData && adminData.name) || ""}</S.Name>
              <EditIcon onClick={handleOnEditName} />
            </S.NameContainer>
          )}
        </S.InfoWrap>
        <S.InfoWrap>
          <S.Label>대표 사진</S.Label>
          <S.ImageList>
            {imageArr.map((item, i) => (
              <S.ImageContainer key={item}>
                {adminData && adminData[item] ? (
                  <S.Image src={adminData[item]} />
                ) : (
                  <>
                    <S.ImageInput
                      name={item}
                      ref={(el) => setImageInputRef(el, i)}
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <S.ImageUpload onClick={() => handleUploadClick(i)}>
                      사진 업로드
                    </S.ImageUpload>
                  </>
                )}
                {adminData && adminData[item] && (
                  <S.Delete onClick={() => handleDeleteImage(item)}>-</S.Delete>
                )}
              </S.ImageContainer>
            ))}
          </S.ImageList>
        </S.InfoWrap>
      </S.Container>
    </PageLayout>
  );
}
