import dayjs from "dayjs";
import React from "react";
import { safeParseAndFormat } from "../../../lib/utils/formatSafeNumber";
import {
  ServerStuff,
  StuffHistoryHeader,
  StuffHistoryResponse,
} from "../../../types/stuff.type";
import * as S from "./StuffHistoryTable.style";

const headers: { type: keyof StuffHistoryHeader; name: string }[] = [
  { type: "name", name: "제품명" },
  { type: "image", name: "이미지" },
  { type: "size", name: "규격" },
  { type: "carbon_emissions", name: "탄소배출량/ea" },
  { type: "carbon_unit", name: "단위" },
  { type: "unit_quantity", name: "포장 수량" },
  { type: "carbon", name: "탄소량" },
];

interface StuffHistoryTableProps {
  stuff: StuffHistoryResponse;
}
export default function StuffHistoryTable({ stuff }: StuffHistoryTableProps) {
  const histories = stuff.histories;
  const totalCnt = histories.reduce((acc, cur) => {
    let current = cur.box_quantity * stuff.unit_quantity + cur.single_quantity;
    return acc + current;
  }, 0);

  const returnItem = (
    type: keyof StuffHistoryHeader,

    stuff: StuffHistoryResponse,
  ) => {
    switch (type) {
      case "image":
        return <S.Image key={type} src={stuff.image} alt="product" />;

      case "carbon": {
        if (stuff.histories.length === 0)
          return <S.GridItem key={type}>{0}</S.GridItem>;
        const total = stuff.histories.reduce((acc, cur) => {
          let currentTotal =
            cur.box_quantity * stuff.unit_quantity + cur.single_quantity;
          return acc + currentTotal;
        }, 0);
        return (
          <S.GridItem key={type}>
            {safeParseAndFormat(total * stuff.carbon_emissions || 0)}
          </S.GridItem>
        );
      }

      default:
        return <S.GridItem key={type}>{stuff[type]}</S.GridItem>;
    }
  };

  return (
    <S.Container>
      <S.GridRow>
        {headers.map((item) => (
          <S.GridItem key={item.type} $isHeader>
            {item.name}
          </S.GridItem>
        ))}
        {headers.map((item) => returnItem(item.type, stuff))}
      </S.GridRow>
      <S.InAndOutWrap>
        {/* 출하내역 */}
        <S.InAndOutContainer>
          <S.InAndOutTitle>출하 내역</S.InAndOutTitle>
          {stuff.histories && stuff.histories.length > 0 && (
            <S.InOutText>
              총 출하량 : {safeParseAndFormat(totalCnt)}
            </S.InOutText>
          )}
          {stuff.histories && stuff.histories.length > 0 && (
            <S.InAndOutGrid $gtc="1fr 1fr">
              <S.GridItem $isHeader>날짜</S.GridItem>
              <S.GridItem $isHeader>출하량</S.GridItem>
              {stuff.histories.map((item, i) => (
                <React.Fragment key={i}>
                  <S.GridItem>
                    {dayjs(item.created_at).format("YYYY-MM-DD HH:mm")}
                  </S.GridItem>
                  <S.GridItem>
                    {safeParseAndFormat(
                      Math.abs(
                        item.box_quantity * stuff.unit_quantity +
                          item.single_quantity,
                      ),
                    )}
                  </S.GridItem>
                </React.Fragment>
              ))}
            </S.InAndOutGrid>
          )}
          {(!stuff.histories || stuff.histories.length === 0) && (
            <S.InOutText>출고 내역이 없습니다.</S.InOutText>
          )}
        </S.InAndOutContainer>
      </S.InAndOutWrap>
    </S.Container>
  );
}
