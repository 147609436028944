export const safeParseAndFormat = (input: any) => {
  // 입력이 null, undefined, 숫자 등 문자열이 아닌 경우를 처리
  if (typeof input !== "string") {
    input = String(input); // 숫자나 다른 타입일 경우 문자열로 변환
  }

  // 입력이 빈 문자열이거나 유효하지 않은 경우 0 반환
  const numericValue = input.match(/^-?\d+(\.\d+)?$/) ? parseFloat(input) : NaN;

  // 숫자로 변환된 값이 NaN이면 0을 반환
  const finalValue = isNaN(numericValue) ? 0 : numericValue;

  // 세 자리마다 콤마를 찍은 숫자 문자열 반환
  return finalValue.toLocaleString();
};
