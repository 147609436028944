import { useState } from "react";
import useAlert from "../../lib/hooks/useAlert";
import { useAddAdmin } from "../../services/auth.service";

export interface AddAdminPayload {
  name: string;
  login_id: string;
  password: string;
  password_check: string;
}

const initialState = {
  name: "",
  login_id: "",
  password: "",
  password_check: "",
};

export const useAddAdminHook = () => {
  const [payload, setPayload] = useState<AddAdminPayload>(initialState);

  const { mutate: addMutate } = useAddAdmin();

  const { showError } = useAlert();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate(payload)) {
      showError("모든 데이터를 입력해주세요");
      return;
    }
    addMutate(payload);
  };

  const validate = (state: AddAdminPayload) => {
    if (state.name.trim() === "") {
      return false;
    }
    if (state.login_id.trim() === "") {
      return false;
    }
    if (state.password.trim() === "") {
      return false;
    }
    if (state.password !== state.password_check) {
      return false;
    }
    return true;
  };

  const checkPassword = (state: AddAdminPayload) => {
    if (state.password !== payload.password_check) {
      return false;
    }
    return true;
  };

  return { payload, handleChange, handleSubmit, validate, checkPassword };
};
