import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { styled } from "styled-components";
import { headerState } from "../atoms/header.atom";
import { useAdminList } from "../services/master.service";

const menus = [
  { id: 1, name: "제품 입출하 내역", path: "/product/history" },
  { id: 2, name: "부자재 사용 내역", path: "/stuff/history" },
  { id: 3, name: "시작 재고 수량", path: "/product/start" },
  { id: 4, name: "종료 재고 수량", path: "/product/end" },
  { id: 5, name: "제품 등록", path: "/product/list" },
  { id: 6, name: "부자재 등록", path: "/stuff/list" },
  { id: 7, name: "기업 정보 변경", path: "/setting" },
];

export default function Menu() {
  const navigate = useNavigate();
  const { adminListData, isError, isLoading, refetch } = useAdminList(1, 1000);
  const [headerText, setHeaderText] = useRecoilState(headerState);

  const onClickNavigate = (path: string) => {
    navigate(path);
  };

  const onClickLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const onClickMasterMenu = (id: number, name: string) => {
    setHeaderText(name);
    onClickNavigate(`/master/admin/${id}`);
  };

  return (
    <Container>
      <MenuButtonWrapper>
        {localStorage.getItem("type") === "master" &&
        !isError &&
        adminListData &&
        adminListData.length > 0 ? (
          <>
            {adminListData.map(({ id, name }, index) => {
              return (
                <MenuButton
                  key={`${id} + ${index}`}
                  onClick={() => onClickMasterMenu(id, name)}
                >
                  {name}
                </MenuButton>
              );
            })}
            <MenuButton key="기업추가" onClick={() => onClickNavigate("/add")}>
              기업추가
            </MenuButton>
          </>
        ) : (
          menus.map(({ id, name, path }) => (
            <MenuButton key={id} onClick={() => onClickNavigate(path)}>
              {name}
            </MenuButton>
          ))
        )}
      </MenuButtonWrapper>

      {/* {menus.map(({ id, name, path }) => (
        <MenuButton key={id} onClick={() => onClickNavigate(path)}>
          {name}
        </MenuButton>
      ))} */}
      <LogoutWrap onClick={onClickLogout}>로그아웃</LogoutWrap>
    </Container>
  );
}

const Container = styled.nav`
  width: 200px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const MenuButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MenuButton = styled.menu`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;

export const LogoutWrap = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 20px;
`;
