import { computeHeadingLevel } from "@testing-library/react";
import React from "react";
import { useRecoilState } from "recoil";
import { styled } from "styled-components";
import logoSrc from "../assets/images/logo.png";
import { headerState } from "../atoms/header.atom";

export default function Header() {
  const name = localStorage.getItem("title") || "";
  const type = localStorage.getItem("type") || "normal";
  const [headerText, setHeaderText] = useRecoilState(headerState);

  return (
    <Container>
      <Layout>
        <LogoImage src={logoSrc} alt="logo" />
        {type === "normal" && <Text>{name}</Text>}
        {type === "master" && headerText === "" && <Text>{name}</Text>}
        {type === "master" && headerText !== "" && (
          <Text>{`${name} - ${headerText}`}</Text>
        )}
      </Layout>
    </Container>
  );
}

const Container = styled.header`
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white; // 배경 색상 설정
  padding: 10px;
  box-shadow:
    0 4px 4px -2px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.06); // 그림자 추가
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

export const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 25px;
`;

export const LogoImage = styled.img`
  position: absolute;
  left: 10px;
  width: 50px;
  height: 50px;
  object-fit: contain;
`;
