import * as S from "./AddAdmin.style";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import { useAddAdminHook } from "./AddAdmin.hook";
import Button from "../../components/common/button/Button";

export default function AddAdmin() {
  const { payload, handleChange, handleSubmit, validate, checkPassword } =
    useAddAdminHook();
  return (
    <PageLayout title="기업추가">
      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <S.InputWrap>
            <S.InputLabel>기업이름</S.InputLabel>
            <S.Input name="name" value={payload.name} onChange={handleChange} />
          </S.InputWrap>
          <S.InputWrap>
            <S.InputLabel>아이디</S.InputLabel>
            <S.Input
              name="login_id"
              value={payload.login_id}
              onChange={handleChange}
            />
          </S.InputWrap>
          <S.InputWrap>
            <S.InputLabel>비밀번호</S.InputLabel>
            <S.Input
              name="password"
              value={payload.password}
              onChange={handleChange}
              type="password"
            />
          </S.InputWrap>
          <S.InputWrap>
            <S.InputLabel>비밀번호 확인</S.InputLabel>
            <S.Input
              name="password_check"
              value={payload.password_check}
              onChange={handleChange}
              type="password"
            />
            {!checkPassword(payload) && (
              <S.ValidatePassword>
                비밀번호가 일치하지 않습니다.
              </S.ValidatePassword>
            )}
          </S.InputWrap>
          <S.ButtonWrap>
            <Button disabled={!validate(payload)}>추가</Button>
          </S.ButtonWrap>
        </S.Form>
      </S.Container>
    </PageLayout>
  );
}
