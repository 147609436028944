import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  gap: 10px;
`;

export const GridRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 3px;
  grid-column-gap: 3px;
`;

export const GridItem = styled.div<{ $isHeader?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px;

  justify-content: center;
  align-items: center;
  ${({ $isHeader }) => $isHeader && `font-weight:700`};
  background-color: ${({ theme }) => theme.colors.tableBlue};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 50px;
  object-fit: contain;
`;

export const InAndOutWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  /* background-color: red; */
  justify-content: flex-start;
`;
export const InAndOutContainer = styled.div`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const InAndOutTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
`;

export const InAndOutGrid = styled.div<{ $gtc: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $gtc }) => $gtc};
  grid-row-gap: 3px;
  grid-column-gap: 3px;
`;

export const InOutText = styled.p``;

export const ButtonWrap = styled.div``;

export const EditModalContainer = styled.div`
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputLabel = styled.p`
  font-size: 20px;
`;

export const EditInput = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  font-size: 18px;
`;

export const DateDiv = styled.div`
  font-size: 18px;
`;

export const ModalButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: auto;
`;

export const TitleWrap = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const AddModalContainer = styled.div`
  width: 500px;
  height: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  gap: 25px;
`;

export const AddInputWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AddInputLabel = styled.p`
  font-size: 20px;
`;

export const AddInput = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  font-size: 18px;
`;

export const AddButtonWrap = styled.div`
  margin-top: auto;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;
