import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 20px;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  overflow: auto;
`;

export const PageTop = styled.div`
  width: 100%;
  /* background-color: green; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
