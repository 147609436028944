import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAllProducts, useInOutData } from "../../services/product.service";
import {
  useGetAdminProducts,
  useGetMasterAdminProductInout,
} from "../../services/master.service";

export default function useMasterProductHistory() {
  const { admin_id, product_id } = useParams();
  const navigate = useNavigate(); // useNavigate 훅 추가
  const endDate = dayjs().format("YYYY-MM-DD");
  const firstDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const [selectProductId, setSelectProductId] = useState(Number(product_id));
  const [date, setDate] = useState({
    firstDate: firstDate || "",
    endDate: endDate || "",
  });
  const firstDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);

  const { data: inOutData } = useGetMasterAdminProductInout({
    admin_id: Number(admin_id),
    product_id: selectProductId === 0 ? undefined : selectProductId,
    ...date,
  });

  const { data: allProduct } = useGetAdminProducts(
    Number(admin_id),
    1,
    true,
    1000,
  );

  console.log("inOutdat", inOutData);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const newProductId = parseInt(value);
    setSelectProductId(newProductId);
    navigate(`/master/admin/${admin_id}/product/${newProductId}`);
  };

  const handleSetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDate((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickDate = (type: "firstDate" | "endDate") => {
    if (type === "firstDate") {
      firstDateRef.current?.showPicker();
      console.log("hi");
      return;
    }
    if (type === "endDate") {
      endDateRef.current?.showPicker();
      return;
    }
  };

  return {
    date,
    handleSetDate,
    firstDateRef,
    endDateRef,
    handleClickDate,
    inOutData,
    allProduct,
    selectProductId,
    handleSelectChange,
  };
}
