import React from "react";
import useProductHistory from "../ProductionHistory/ProductHistory.hook";
import { useParams } from "react-router-dom";
import useMasterProductHistory from "./MasterProductInoutPage.hook";
import { styled } from "styled-components";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import ProductHistoryTable from "../../components/ui/productHistoryTable/ProductHistoryTable";
import MasterProductHistoryTable from "../../components/ui/productHistoryTable/MasterProductHistoryTable";

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  gap: 30px;
  overflow-x: auto;
`;

const ToolContainer = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ToolRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const ToolTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.borderGray};
  padding: 5px 10px;
`;

const DateContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const DateString = styled.p`
  cursor: pointer;
`;

const InputDate = styled.input`
  opacity: 1;
  visibility: hidden;
  width: 0px;
  height: 0px;
`;

const MasterProductInoutPage = () => {
  const {
    date,
    handleSetDate,
    firstDateRef,
    endDateRef,
    handleClickDate,
    inOutData,
    allProduct,
    selectProductId,
    handleSelectChange,
  } = useMasterProductHistory();

  return (
    <PageLayout title="제품 입출하 내역">
      <Container>
        <ToolContainer>
          <ToolRow>
            <ToolTag>기간 설정</ToolTag>
            <DateContainer>
              <DateString onClick={() => handleClickDate("firstDate")}>
                {date.firstDate}
                <InputDate
                  ref={firstDateRef}
                  name="firstDate"
                  onChange={handleSetDate}
                  value={date.firstDate}
                  type="date"
                />
              </DateString>
              ~
              <DateString onClick={() => handleClickDate("endDate")}>
                {date.endDate}
                <InputDate
                  ref={endDateRef}
                  name="endDate"
                  onChange={handleSetDate}
                  value={date.endDate}
                  type="date"
                />
              </DateString>
            </DateContainer>
          </ToolRow>
          <ToolRow>
            <ToolTag>제품 설정</ToolTag>
            {/*TODO: 여기에 select로 용기 고를 수 있도록 하기 */}
            <select
              style={{
                minWidth: 150,
                padding: 5,
                fontSize: 14,
                borderRadius: 8,
              }}
              value={selectProductId}
              onChange={handleSelectChange}
            >
              {allProduct?.items.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </ToolRow>
        </ToolContainer>
        {inOutData?.map((item) => (
          //@ts-ignore
          <MasterProductHistoryTable key={item.id} product={item} />
        ))}
      </Container>
    </PageLayout>
  );
};

export default MasterProductInoutPage;
