import { Paging, SuccessRes } from "../types/common.type";
import {
  IAdmin,
  IAdminProductInout,
  ICreateProductInoutPayload,
  IDeleteMasterAdminProductInoutPayload,
  IGetAdminProductInoutPayload,
  IUpdateMasterAdminProductInoutPayload,
} from "../types/master.types";
import {
  Product,
  ProductInoutResponse,
  ServerProduct,
} from "../types/products";
import { ServerStuff } from "../types/stuff.type";
import api from "./axios";

// 어드민 목록 조회
export const getAdmins = async (payload: {
  pageNum: number;
  perPage?: number;
}) => {
  const { data } = await api.get<SuccessRes<Paging<IAdmin>>>("/master/admins", {
    params: { ...payload },
  });
  return data.data;
};

// 마스터 어드민 제품 조회
export const getMasterAdminProducts = async (
  adminId: number,
  pageNum: number,
  perPage?: number,
) => {
  const { data } = await api.get<SuccessRes<Paging<Product>>>(
    `/master/admin/${adminId}/products`,
    {
      params: { pageNum, perPage },
    },
  );

  return data.data;
};

// 마스터 어드민의 부자재 조회
export const getMasterAdminStuffs = async (
  adminId: number,
  pageNum: number,
) => {
  const { data } = await api.get<SuccessRes<Paging<ServerStuff>>>(
    `/master/admin/${adminId}/stuffs`,
    {
      params: { pageNum },
    },
  );

  return data.data;
};

// 마스터 어드민 제품 삭제
export const deleteMasterAdminProduct = async ({
  admin_id,
  product_id,
}: {
  admin_id: number;
  product_id: number;
}) => {
  await api.delete(`/master/admin/${admin_id}/products/${product_id}`);
};

// 마스터 어드민 부자재 삭제
export const deleteMasterAdminStuff = async ({
  admin_id,
  stuff_id,
}: {
  admin_id: number;
  stuff_id: number;
}) => {
  await api.delete(`/master/admin/${admin_id}/stuffs/${stuff_id}`);
};

// 마스터 어드민 제품 수정
export const updateMasterAdminProduct = async ({
  payload,
  product_id,
  admin_id,
}: {
  payload: FormData;
  admin_id: number;
  product_id: number;
}) => {
  await api.put(`/master/admin/${admin_id}/products/${product_id}`, payload);
};

// 마스터 어드민 제품 수정
export const updateMasterAdminStuff = async ({
  payload,
  stuff_id,
  admin_id,
}: {
  payload: FormData;
  admin_id: number;
  stuff_id: number;
}) => {
  await api.put(`/master/admin/${admin_id}/stuffs/${stuff_id}`, payload);
};

// 마스터 제품 입출고 내역 조회
export const getMasterAdminProductInout = async ({
  admin_id,
  endDate,
  firstDate,
  product_id,
}: IGetAdminProductInoutPayload) => {
  const { data } = await api.get<SuccessRes<IAdminProductInout[]>>(
    `/master/admin/${admin_id}/products/in-out/${product_id}`,
    {
      params: {
        firstDate,
        endDate,
      },
    },
  );

  return data.data;
};

// 마스터 어드민 제품 입출고 내역 삭제
export const deleteMasterAdminProductInout = async ({
  admin_id,
  history_id,
}: IDeleteMasterAdminProductInoutPayload) => {
  await api.delete(`/master/admin/${admin_id}/products/history/${history_id}`);
};

// 마스터 어드민 제품 입출고 내역 업데이트
export const updateMasterAdminProductInout = async ({
  admin_id,
  history_id,
  ...body
}: IUpdateMasterAdminProductInoutPayload) => {
  await api.put(
    `/master/admin/${admin_id}/products/history/${history_id}`,
    body,
  );
};

// 마스터 어드민 제품 입출고 등록
export const createMasterAdminProductInout = async ({
  admin_id,
  ...body
}: ICreateProductInoutPayload) => {
  await api.post(
    `/master/admin/${admin_id}/products/in-out/${body.product_id}`,
    body,
  );
};
