import { Paging } from "../types/common.type";
import api from "./axios";

export const getMasterStuffHistories = async (
  adminId: string,
  stuffId: string,
  pageNum: number,
  firstDate: string,
  endDate: string,
) => {
  const { data } = await api.get(
    `/master/admin/${adminId}/stuffs/in-out/${stuffId}`,
    { params: { pageNum, firstDate, endDate } },
  );
  return data.data;
};

export const getMasterStuffAll = async (
  adminId: string,
): Promise<Paging<{ id: number; name: string }>> => {
  const { data } = await api.get(
    `/master/admin/${adminId}/stuffs?pageNum=1&perPage=1000`,
  );
  return data.data;
};

export interface EditMasterStuffHistoryPayload {
  box_quantity: number;
  single_quantity: number;
  date_time: string;
}
export const editMasterStuffHistory = async (
  adminId: string,
  historyId: number,
  payload: EditMasterStuffHistoryPayload,
) => {
  const { data } = await api.put(
    `/master/admin/${adminId}/stuffs/history/${historyId}`,
    payload,
  );
  return data.data;
};

export const deleteMasterStuffHistory = async (
  adminId: string,
  historyId: number,
) => {
  const { data } = await api.delete(
    `/master/admin/${adminId}/stuffs/history/${historyId}`,
  );
  return data.data;
};

export interface AddMasterStuffHistoryPayload {
  stuff_id: number;
  box_quantity: number;
  single_quantity: number;
  date_time: string;
}

export const addMasterStuffHistory = async (
  adminId: string,
  payload: AddMasterStuffHistoryPayload,
) => {
  const { data } = await api.post(
    `/master/admin/${adminId}/stuffs/in-out/${payload.stuff_id}`,
    payload,
  );
  return data.data;
};
