import { Navigate } from "react-router-dom";
import useAuth from "../lib/hooks/useAuth";

interface ProtectedRouterProps {
  children: React.ReactNode;
}
export default function ProtectedRouter({ children }: ProtectedRouterProps) {
  const isLoggedIn = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return children;
}
