import { Paging } from "../types/common.type";
import { ServerStuff, StuffHistoryResponse } from "../types/stuff.type";
import api from "./axios";

export const getStuffList = async (
  pageNum: number,
): Promise<Paging<ServerStuff>> => {
  const { data } = await api.get("/stuff", {
    params: {
      pageNum,
    },
  });
  return data.data;
};

export const addStuff = async (payload: FormData) => {
  const { data } = await api.post("/stuff", payload);
  return data.data;
};

export const editStuff = async (payload: FormData) => {
  const { data } = await api.put("/stuff/edit", payload);
  return data.data;
};

export const deleteStuff = async (stuffId: number) => {
  const { data } = await api.delete("/stuff", { data: { id: stuffId } });
  return data.data;
};

export interface GetStuffHistoryPayload {
  stuffId?: number;
  firstDate: string;
  endDate: string;
}
export const getStuffHistory = async (
  payload: GetStuffHistoryPayload,
): Promise<StuffHistoryResponse[]> => {
  const { data } = await api.get("/stuff/history", { params: payload });
  return data.data;
};

export const getAllStuff = async (): Promise<
  { id: number; name: string }[]
> => {
  const { data } = await api.get("/stuff/list");
  return data.data;
};
