import { styled } from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginBox = styled.div`
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  gap: 60px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

export const IntroText = styled.p`
  color: #9b9c92;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
`;

export const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding-bottom: 5px;
`;

export const InputIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

export const Input = styled.input`
  display: flex;
  flex-grow: 1;
  color: #9b9c92;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
  background-color: transparent;
  outline: none;
  border: none;

  &::placeholder {
    color: #9b9c92;
  }
`;

export const Button = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.yellow};
  height: 40px;
  color: white;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
  border-radius: 10px;
`;
