const formatKoreanWon = (value: string | number | null) => {
  if (!value) return;
  let willKoreanWon;
  typeof value === "string"
    ? (willKoreanWon = Number(value))
    : (willKoreanWon = value);

  willKoreanWon = willKoreanWon.toLocaleString("ko-KR");
  return willKoreanWon;
};

export default formatKoreanWon;
