import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  addProduct,
  deleteProduct,
  editEndQuantity,
  EditEndQuantityPayload,
  editProduct,
  editStartQuantity,
  EditStartQuantityPayload,
  getAllProducts,
  getInOutData,
  GetInOutPayload,
  getProducts,
} from "../api/product.api";
import useAlert from "../lib/hooks/useAlert";
import { CustomAxiosError } from "../types/axios.type";

export const useAddProduct = () => {
  const { admin_id } = useParams(); // 마스터 페이지에서만 존재
  const navigate = useNavigate();
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: FormData) => addProduct(req),
    onSuccess: () => {
      showToast("success", "제품 등록 성공!", 2000);
      queryClient.invalidateQueries({
        queryKey: ["product"],
      });
      if (!admin_id) {
        navigate("/product/list");
      } else {
        navigate(`/master/admin/${admin_id}`);
      }
    },
    onError: () => {
      console.log("failed");
      showToast("error", "제품 등록 실패", 2000);
    },
  });
};

export const useGetProduct = (pageNum: number) => {
  return useQuery({
    queryKey: ["product", pageNum],
    queryFn: () => getProducts(pageNum),
  });
};

export const useEditProduct = (closeModal: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: FormData) => editProduct(req),
    onSuccess: () => {
      showToast("success", "제품 수정 성공!", 2000);
      queryClient.invalidateQueries({
        queryKey: ["product"],
      });
      closeModal();
    },
    onError: (error: CustomAxiosError) => {
      const errorMsg = error.response?.data.message;
      console.error(errorMsg);
      console.log("failed");
      showToast("error", "제품 수정 실패", 3000);
    },
  });
};

export const useDeleteProduct = (closeModal: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: number) => deleteProduct(req),
    onSuccess: () => {
      showToast("success", "제품 삭제 성공!", 2000);
      queryClient.invalidateQueries({
        queryKey: ["product"],
      });
      closeModal();
    },
    onError: (error: CustomAxiosError) => {
      const errorMsg = error.response?.data.message;
      console.error(errorMsg);
      showToast("error", errorMsg ? errorMsg : "제품 삭제 실패", 2000);
    },
  });
};

export const useInOutData = (payload: GetInOutPayload) => {
  return useQuery({
    queryKey: ["in-out", payload.firstDate, payload.endDate, payload.productId],
    queryFn: () => getInOutData(payload),
  });
};

export const useAllProducts = () => {
  return useQuery({
    queryKey: ["all-products"],
    queryFn: () => getAllProducts(),
  });
};

export const useEditStartQuantity = (closeModal: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: EditStartQuantityPayload) => editStartQuantity(req),
    onSuccess: () => {
      showToast("success", "시작재고 변경 성공!", 2000);
      queryClient.invalidateQueries({
        queryKey: ["product"],
      });
      closeModal();
    },
    onError: (error: CustomAxiosError) => {
      const errMsg = error.response?.data.message;
      showToast("error", errMsg ? errMsg : "시작재고 변경 실패", 2000);
    },
  });
};

export const useEditEndQuantity = (closeModal: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: EditEndQuantityPayload) => editEndQuantity(req),
    onSuccess: () => {
      showToast("success", "종료재고 변경 성공!", 2000);
      queryClient.invalidateQueries({
        queryKey: ["product"],
      });
      closeModal();
    },
    onError: (error: CustomAxiosError) => {
      const errMsg = error.response?.data.message;
      showToast("error", errMsg ? errMsg : "종료재고 변경 실패", 2000);
    },
  });
};
