import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Label = styled.p`
  font-size: 20px;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  font-size: 18px;
`;

export const ImageInputBox = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  /* border:2px dashed ${({ theme }) => theme.colors.blue}; */
  border-radius: 10px;
  /* overflow:hidden; */
  padding: 5px;
  cursor: pointer;
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;
