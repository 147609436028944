import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "styled-components";
import Header from "./Header";
import Menu from "./Menu";

export default function Layout() {
  return (
    <Container>
      <Header />
      <Menu />
      <Body>
        <Outlet />
      </Body>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  background-color: #eeeeee;
`;

const Body = styled.div`
  flex-grow: 1;
`;
