import React from "react";
import Button from "../button/Button";
import * as S from "./PageLayout.style";

interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
  showAdd?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  showExcel?: boolean;
  addFunction?: () => void;
  editFunction?: () => void;
  deleteFunction?: () => void;
  getExcelFunction?: () => void;
}
export default function PageLayout({
  children,
  title = "제목입력",
  showAdd,
  showEdit,
  showDelete,
  showExcel,
  getExcelFunction,
  addFunction,
  editFunction,
  deleteFunction,
}: PageLayoutProps) {
  return (
    <S.Container>
      <S.PageTop>
        <S.Title>{title}</S.Title>
        <S.ButtonWrapper>
          {showAdd && <Button onClick={addFunction}>추가</Button>}
          {showEdit && (
            <Button onClick={editFunction} backgroundColor="yellow">
              수정
            </Button>
          )}
          {showDelete && (
            <Button onClick={deleteFunction} backgroundColor="red">
              삭제
            </Button>
          )}
          {showExcel && (
            <Button
              onClick={getExcelFunction}
              backgroundColor="yellow"
              fontColor="white"
            >
              엑셀 다운로드
            </Button>
          )}
        </S.ButtonWrapper>
      </S.PageTop>
      {children}
    </S.Container>
  );
}
