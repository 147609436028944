import * as XLSX from "xlsx";
// DATA 가공영역
const generateExcelData = (apiData: any) => {
  // Extract all unique dates from history
  const uniqueDates = Array.from(
    new Set(
      apiData.flatMap((item: any) =>
        item.histories.map((history: any) => history.created_at.split("T")[0]),
      ),
    ),
  ).sort(); // 날짜를 오름차순으로 정렬

  // Create Excel rows
  const rows: any[] = [];
  const headers = [
    "제품명",
    "규격",
    "탄소배출량/ea",
    "단위",
    "포장수량",
    "탄소량",
  ];

  // Find the maximum number of actions per date
  const maxActionsPerDate = uniqueDates.reduce((acc, date) => {
    const actionsCount = Math.max(
      ...apiData.map(
        (product: any) =>
          product.histories.filter((history: any) =>
            history.created_at.startsWith(date),
          ).length,
      ),
    );
    //@ts-ignore

    acc[date] = actionsCount;
    return acc;
  }, {});

  // Add dynamic headers based on dates
  uniqueDates.forEach((date) => {
    headers.push(`${date}`); // 날짜는 한 번만 추가
    //@ts-ignore
    for (let i = 1; i < maxActionsPerDate[date]; i++) {
      headers.push(""); // 해당 날짜 아래에 빈 칸 추가
    }
  });

  // Loop through each product and create rows
  apiData.forEach((product: any) => {
    const totalUsage = product.histories.reduce(
      (acc: number, cur: any) =>
        acc + cur.box_quantity * product.unit_quantity + cur.single_quantity,
      0,
    );
    const totalCarbon = totalUsage * product.carbon_emissions || 0;

    const row = [
      product.name,
      product.size,
      product.carbon_emissions,
      product.carbon_unit,
      product.unit_quantity,

      totalCarbon,
    ];

    // Add date-specific data
    uniqueDates.forEach((date) => {
      const dateData = product.histories.filter((entry: any) =>
        entry.created_at.startsWith(date),
      );

      // 첫 번째 액션에만 날짜를 추가하고 나머지는 빈 칸으로 채움
      if (dateData.length > 0) {
        // 날짜에 해당하는 첫 액션
        const quantity =
          product.unit_quantity * dateData[0].box_quantity +
          dateData[0].single_quantity;
        row.push(`${Math.abs(quantity)}`);

        // 나머지 액션들은 빈 칸 없이 추가
        for (let i = 1; i < dateData.length; i++) {
          const quantity =
            product.unit_quantity * dateData[i].box_quantity +
            dateData[i].single_quantity;
          row.push(`${Math.abs(quantity)}`);
        }
      }

      // 해당 날짜에 액션이 없는 경우 빈 칸으로 채움
      //@ts-ignore

      for (let i = dateData.length; i < maxActionsPerDate[date]; i++) {
        row.push("");
      }
    });

    // Push the row to the rows array
    rows.push(row);
  });

  return [headers, ...rows];
};

// Excel 변환 & 다운로드
const handleDownloadExcel = (csvData: any) => {
  // 워크시트 생성
  const worksheet = XLSX.utils.aoa_to_sheet(csvData);

  // 열 너비 계산
  const columnWidths = csvData[0].map((_: any, colIndex: number) => {
    const maxWidth = Math.max(
      ...csvData.map((row: any) =>
        row[colIndex] ? row[colIndex].toString().length : 10,
      ),
    );
    return { wch: maxWidth + 3 }; // wch: width in characters, +2 for padding
  });

  // 열 너비 적용
  worksheet["!cols"] = columnWidths;

  // 워크북 생성
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "부자재출고내역");

  // 엑셀 파일 다운로드
  XLSX.writeFile(workbook, "부자재출고내역.xlsx");
};

export const stuffHistoriesExcel = (data: any) => {
  const generatedData = generateExcelData(data);
  console.log(generatedData);
  return handleDownloadExcel(generatedData);
};
