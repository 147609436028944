import React, { useRef, useState } from "react";
import useAlert from "../../lib/hooks/useAlert";
import { useAddProduct } from "../../services/product.service";
import { Product, ProductForm } from "../../types/products";
import { useParams } from "react-router-dom";

const initialState: ProductForm = {
  name: "",
  image: null,
  size: "",
  unit_quantity: 0,
  carbon_emissions: 0,
  carbon_unit: "",
  memo: "",
};

export default function useProductAdd() {
  const { admin_id: admin_id_from_master } = useParams();
  const [product, setProduct] = useState(initialState);
  const [imagePreview, setImagePreview] = useState<string | null>();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const { showError } = useAlert();

  const { mutate: addMutate } = useAddProduct();

  const handleSubmit = () => {
    if (!validateProduct()) {
      showError("비고를 제외하고 모두 입력해야 합니다.");
    }
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("size", product.size);
    formData.append("unit_quantity", product.unit_quantity.toString());
    formData.append("carbon_emissions", product.carbon_emissions.toString());
    formData.append("carbon_unit", product.carbon_unit);
    if (admin_id_from_master) {
      formData.append("admin_id_from_master", admin_id_from_master);
    }

    if (product.image) {
      formData.append("image", product.image);
    }

    addMutate(formData);
  };

  const handleChangeProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setProduct((prev) => ({ ...prev, image: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClickImageInput = () => {
    imageInputRef.current?.click();
  };

  const validateProduct = () => {
    const { name, image, size, unit_quantity, carbon_emissions, carbon_unit } =
      product;
    return Boolean(
      name.trim() && // 이름이 존재하고 공백이 아닌지 확인
        size.trim() && // 사이즈가 존재하고 공백이 아닌지 확인
        unit_quantity >= 0 && // 수량이 0보다 큰지 확인
        carbon_emissions >= 0 && // 탄소 배출량이 0보다 큰지 확인
        carbon_unit.trim() && // 단위가 존재하고 공백이 아닌지 확인
        image, // 이미지가 존재하는지 확인
    );
  };

  return {
    product,
    handleChangeProduct,
    handleChangeImage,
    imagePreview,
    imageInputRef,
    handleClickImageInput,
    validateProduct,
    handleSubmit,
  };
}
