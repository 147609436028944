export const fontSize = {
  xs: "0.5rem",
  sm: "0.75rem",
  base: "1rem",
  md: "1.25rem",
  lg: "1.5rem",
  xlg: "1.75rem",
};

export const fontWeight = {
  thin: 100, // 매우 얇은 굵기, 섬세한 텍스트나 장식적인 용도에 적합
  light: 300, // 가벼운 굵기, 부제목이나 강조가 필요하지 않은 텍스트에 적합
  regular: 400, // 기본 굵기, 본문 텍스트에 적합
  medium: 500, // 중간 굵기, 중요한 정보나 부제목에 적합
  bold: 700, // 굵은 굵기, 제목이나 중요한 요소에 적합
};

export const colors = {
  black: "#000000",
  white: "#FFFFFF",
  yellow: "#FFC144",
  blue: "#59A9FF",
  red: "#FF5C5C",
  gray: "#93969B",
  borderGray: "#D2D7DC",
  lightBlue: "#B7DAFF",
  tableBlue: "#E0F2FF",
};

export const theme = { colors, fontSize, fontWeight };
