import axios from "axios";
// const BASE_URL = process.env.REACT_APP_BASE_URL_LOCAL;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use((config) => {
  /* access token은 저장된 곳에서 불러오기 (프로젝트마다 다를 수 있음) */
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

export default api;
