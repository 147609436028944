import { editProduct, editStartQuantity } from "./../../api/product.api";
import { useState } from "react";
import usePage from "../../lib/hooks/usePage";
import {
  useEditStartQuantity,
  useGetProduct,
} from "../../services/product.service";
import useAlert from "../../lib/hooks/useAlert";
import { getAllProductsExcelData } from "../../api/excel.api";
import { productExcel } from "../../lib/utils/excel/product";

export interface EditStartProduct {
  id: number;
  start_box_quantity: number;
  start_single_quantity: number;
  name: string;
}

export const useProductStartQuantity = () => {
  const [modal, setModal] = useState(false);
  const { pageNum, onClickPageHandler } = usePage();
  const { data: products } = useGetProduct(pageNum);
  const [editStartProduct, setEditStartProduct] =
    useState<EditStartProduct | null>(null);

  const handleCloseModal = () => {
    setModal(false);
  };
  const { mutate: editStartQuantityMutate } =
    useEditStartQuantity(handleCloseModal);
  const { showError } = useAlert();

  const handleSubmitEditStartQuantity = () => {
    if (!editStartProduct)
      return showError("수정할 데이터를 불러오지 못했습니다.");
    const { id, start_box_quantity, start_single_quantity } = editStartProduct;

    editStartQuantityMutate({
      productId: id,
      start_box_quantity,
      start_single_quantity,
    });
  };

  const handleClickRow = (payload: EditStartProduct) => {
    setModal(true);
    setEditStartProduct(payload);
  };

  const handleChangeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditStartProduct((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const validateEditState = () => {
    const { start_box_quantity, start_single_quantity } =
      editStartProduct || {};
    console.log(start_box_quantity, start_single_quantity);
    return Boolean(
      start_box_quantity !== undefined &&
        start_box_quantity >= 0 &&
        start_single_quantity !== undefined &&
        start_single_quantity >= 0,
    );
  };

  const handleClickExcel = async () => {
    const data = await getAllProductsExcelData();
    return productExcel(data, "start");
  };

  return {
    pageNum,
    products,
    modal,
    onClickPageHandler,
    handleClickRow,
    editStartProduct,
    handleCloseModal,
    handleChangeEdit,
    validateEditState,
    handleSubmitEditStartQuantity,
    handleClickExcel,
  };
};
