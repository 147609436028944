import * as XLSX from "xlsx";
// DATA 가공영역
const generateExcelData = (apiData: any) => {
  // Create Excel rows
  const rows: any[] = [];
  const row = [
    "제품명",
    "규격",
    "단위 포장수량",
    "탄소배출량/ea",
    "탄소배출단위",
    "비고",
  ];

  rows.push(row);

  // Loop through each product and create rows
  apiData.forEach((stuff: any) => {
    const row = [
      stuff.name,
      stuff.size,
      stuff.unit_quantity,
      stuff.carbon_emissions,
      stuff.carbon_unit,
      stuff.memo,
    ];

    // Push the row to the rows array
    rows.push(row);
  });

  return rows;
};

// Excel 변환 & 다운로드
const handleDownloadExcel = (csvData: any) => {
  // 워크시트 생성
  const worksheet = XLSX.utils.aoa_to_sheet(csvData);

  // 열 너비 계산
  const columnWidths = csvData[0].map((_: any, colIndex: number) => {
    const maxWidth = Math.max(
      ...csvData.map((row: any) =>
        row[colIndex] ? row[colIndex].toString().length : 10,
      ),
    );
    return { wch: maxWidth + 3 }; // wch: width in characters, +2 for padding
  });

  // 열 너비 적용
  worksheet["!cols"] = columnWidths;

  // 워크북 생성
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "부자재리스트");

  // 엑셀 파일 다운로드
  XLSX.writeFile(workbook, "부자재리스트.xlsx");
};

export const stuffExcel = (data: any) => {
  const generatedData = generateExcelData(data);
  console.log(generatedData);
  return handleDownloadExcel(generatedData);
};
