import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import MainPage from "../pages/MainPage";
import Layout from "../layout/Layout";
import ProductHistory from "../pages/ProductionHistory/ProductHistory";
import ProductList from "../pages/ProductList/ProductList";
import ProductAdd from "../pages/ProductAdd/ProductAdd";
import Login from "../pages/Login/Login";
import ProtectedRouter from "./ProtectedRouter";
import ProductStartQuantity from "../pages/ProductStartQuantity/ProductStartQuantity";
import StuffList from "../pages/StuffList/StuffList";
import StuffAdd from "../pages/SutffAdd/StuffAdd";
import ProductEndQuantity from "../pages/ProductEndQuantity/ProductEndQuantity";
import StuffHistory from "../pages/StuffHistory/StuffHistory";
import MasterPage from "../pages/MasterPage/MasterPage";
import MasterAdminPage from "../pages/MasterAdminPage/MasterAdminPage";
import Setting from "../pages/Setting/Setting";
import MasterProductInoutPage from "../pages/MasterProductInoutPage/MasterProductInoutPage";
import MasterStuffOutPage from "../pages/MasterStuffOutPage/MasterStuffOutPage";
import ExportCSV from "../pages/Test/ExportCSV";
import AddAdmin from "../pages/addAdmin/AddAdmin";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          element={
            //@ts-ignore
            <ProtectedRouter>
              <Layout />
            </ProtectedRouter>
          }
        >
          <Route path="/" element={<ProductHistory />} />
          <Route path="/add" element={<AddAdmin />} />
          <Route path="/product/history" element={<ProductHistory />} />
          <Route path="/product/list" element={<ProductList />} />
          <Route path="/product/add" element={<ProductAdd />} />
          {/* <Route path="/product/edit/:id" element={<ProductAdd />} /> */}
          <Route path="/product/start" element={<ProductStartQuantity />} />
          <Route path="/product/end" element={<ProductEndQuantity />} />
          <Route path="/stuff/history" element={<StuffHistory />} />
          <Route path="/stuff/list" element={<StuffList />} />
          <Route path="/stuff/add" element={<StuffAdd />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/master" element={<MasterPage />} />
          <Route path="/master/admin/:admin_id" element={<MasterAdminPage />} />
          <Route
            path="/master/admin/:admin_id/product/:product_id"
            element={<MasterProductInoutPage />}
          />
          <Route
            path="/master/admin/:admin_id/stuff/:stuff_id"
            element={<MasterStuffOutPage />}
          />
          <Route
            path="/master/admin/:admin_id/stuff/add"
            element={<StuffAdd />}
          />
          <Route
            path="/master/admin/:admin_id/product/add"
            element={<ProductAdd />}
          />
          <Route path="/testtesttest" element={<ExportCSV />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
