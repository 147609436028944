import React from "react";
import { useParams } from "react-router-dom";
import useMasterAdmin from "./MasterAdmin.hook";
import PageLayout from "../../components/common/pageLayout/PageLayout";
import styled from "styled-components";
import { Product } from "../../types/products";
import Pagination from "../../components/common/pagination/Pagination";
import Modal from "../../components/common/modal/Modal";
import Button from "../../components/common/button/Button";
import { theme } from "../../theme/theme";
import { ServerStuff } from "../../types/stuff.type";

/**
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
*/

/**
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
*/
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
  overflow-x: auto;
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const GridRow = styled.div<{ $isHeader?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 100px 2fr 1fr 1fr 1fr 3fr;
  grid-row-gap: 3px;
  grid-column-gap: 3px;
  &:hover {
    ${({ theme, $isHeader }) =>
      !$isHeader && `background-color:${theme.colors.borderGray}`};
    cursor: pointer;
  }
  &:hover > div {
    background-color: transparent;
  }
`;

const GridItem = styled.div<{ $isHeader?: boolean }>`
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tableBlue};
  ${({ $isHeader }) => $isHeader && `font-weight:700`};
`;

const Image = styled.img`
  width: 100px;
  max-height: 50px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
`;

/* Modal */
const EditDeleteModalContainer = styled.div`
  width: 35vw;
  min-width: 600px;
  min-height: 200px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 24px;
`;

const ModalBtnWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: 36px;
`;

const EditModalContainer = styled.div`
  width: 800px;
  height: 90vh;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 40px;
  overflow-y: auto;
`;

const ModalInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputLabel = styled.p`
  font-size: 20px;
`;

const Input = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  font-size: 18px;
`;

const PreviewImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`;

const ButtonWrap = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

/**
----------------------------------------------- 🖼️ 렌더링 🖼️ -----------------------------------------------
----------------------------------------------- 🖼️ 렌더링 🖼️  -----------------------------------------------
----------------------------------------------- 🖼️ 렌더링 🖼️  -----------------------------------------------
----------------------------------------------- 🖼️ 렌더링 🖼️  -----------------------------------------------
*/
const MasterAdminPage = () => {
  const {
    productColumns,
    stuffColumns,
    admin_id,
    handleNavigateToAdd,
    // productOrStuff,
    handleProductOrStuff,
    masterAdminProducts,
    masterAdminStuffs,
    pageNum,
    onClickPageHandler,
    showEditDeleteModal,
    onClickProductOrStuff,
    handleCloseEditOrDeleteModal,
    onClickDelete,
    editModal,
    selectedProduct,
    selectedStuff,
    imageRef,
    handleCloseModal,
    handleClickImage,
    showEdit,
    handleImageChange,
    imagePreview,
    validateProduct,
    handleSubmitProduct,
    handleEditProductChange,
    handleEditStuffChange,
    validateStuff,
    handleSubmitStuff,
    onClickGoDetail,
    filter,
  } = useMasterAdmin();

  const renderItem = (
    type: keyof Product,
    item: string | number | undefined,
  ) => {
    switch (type) {
      case "image":
        return <Image key={type} src={item as string} alt="product" />;
      default:
        return <GridItem key={type}>{item}</GridItem>;
    }
  };

  return (
    <PageLayout
      title={filter === "product" || filter === "" ? "제품" : "부자재"}
      showAdd
      addFunction={handleNavigateToAdd}
    >
      <Container>
        <FilterSection>
          <label style={{ marginRight: 4 }} htmlFor="filter">
            필터 :
          </label>
          <select
            style={{
              borderRadius: 10,
              minWidth: 100,
              height: 30,
              fontSize: 16,
              paddingLeft: 5,
            }}
            name="filter"
            id="filter"
            value={filter}
            onChange={handleProductOrStuff}
          >
            <option value="product">제품</option>
            <option value="stuff">부자재</option>
          </select>
        </FilterSection>

        {filter === "product" || filter === "" ? (
          <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <GridRow $isHeader>
              {productColumns.map((item, i) => (
                <GridItem key={i} $isHeader>
                  {item.name}
                </GridItem>
              ))}
            </GridRow>
            {masterAdminProducts?.items.map((product: Product, index) => {
              return (
                <GridRow
                  key={index}
                  onClick={() => onClickProductOrStuff("product", product)}
                >
                  {productColumns.map((item, index) =>
                    renderItem(item.type, product[item.type]),
                  )}
                </GridRow>
              );
            })}

            <PaginationLayout>
              <Pagination
                currentPage={pageNum}
                totalPages={masterAdminProducts?.totalPages || 5}
                size={5}
                onClickPage={onClickPageHandler}
              />
            </PaginationLayout>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <GridRow $isHeader>
              {stuffColumns.map((item, i) => (
                <GridItem key={i} $isHeader>
                  {item.name}
                </GridItem>
              ))}
            </GridRow>
            {masterAdminStuffs?.items.map((stuff: ServerStuff, index) => {
              return (
                <GridRow
                  key={index}
                  onClick={() => onClickProductOrStuff("stuff", stuff)}
                >
                  {stuffColumns.map((item, index) =>
                    // @ts-ignore
                    renderItem(item.type, stuff[item.type]),
                  )}
                </GridRow>
              );
            })}

            <PaginationLayout>
              <Pagination
                currentPage={pageNum}
                totalPages={masterAdminStuffs?.totalPages || 5}
                size={5}
                onClickPage={onClickPageHandler}
              />
            </PaginationLayout>
          </div>
        )}
      </Container>

      {showEditDeleteModal && (
        <Modal>
          <EditDeleteModalContainer style={{ position: "relative" }}>
            <div
              onClick={handleCloseEditOrDeleteModal}
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",

                fontSize: 36,
                cursor: "pointer",
                top: 5,
                right: 5,
              }}
            >
              X
            </div>
            <h1 style={{ color: theme.colors.red, marginTop: "auto" }}>
              * 삭제 후 복구는 불가능합니다.
            </h1>
            <ModalBtnWrap>
              <Button onClick={onClickGoDetail} backgroundColor="yellow">
                입출고 현황
              </Button>
              <Button onClick={showEdit}>수정</Button>
              <Button onClick={onClickDelete} backgroundColor="red">
                삭제
              </Button>
            </ModalBtnWrap>
          </EditDeleteModalContainer>
        </Modal>
      )}

      {editModal && (
        <Modal>
          <EditModalContainer>
            {selectedProduct ? (
              <>
                {productColumns.map((item, i) => {
                  if (item.type === "image") return;
                  return (
                    <ModalInputWrapper key={i}>
                      <InputLabel>{item.name}</InputLabel>
                      <Input
                        value={selectedProduct && selectedProduct[item.type]}
                        type={
                          item.type === "carbon_emissions" ||
                          item.type === "unit_quantity"
                            ? "number"
                            : "text"
                        }
                        name={item.type}
                        onChange={handleEditProductChange}
                      />
                    </ModalInputWrapper>
                  );
                })}
                <ModalInputWrapper>
                  <InputLabel>이미지</InputLabel>
                  <Input
                    ref={imageRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                  <Image
                    onClick={handleClickImage}
                    src={
                      imagePreview ? imagePreview : selectedProduct?.image || ""
                    }
                    alt="프리뷰"
                  />
                </ModalInputWrapper>
                <ButtonWrap>
                  <Button
                    disabled={!validateProduct()}
                    onClick={handleSubmitProduct}
                  >
                    수정
                  </Button>

                  <Button backgroundColor="gray" onClick={handleCloseModal}>
                    닫기
                  </Button>
                </ButtonWrap>
              </>
            ) : null}
            {selectedStuff ? (
              <>
                {stuffColumns.map((item, i) => {
                  if (item.type === "image") return;
                  return (
                    <ModalInputWrapper key={i}>
                      <InputLabel>{item.name}</InputLabel>
                      <Input
                        //@ts-ignore
                        value={selectedStuff && selectedStuff[item.type]}
                        type={
                          item.type === "carbon_emissions" ||
                          item.type === "unit_quantity"
                            ? "number"
                            : "text"
                        }
                        name={item.type}
                        onChange={handleEditStuffChange}
                      />
                    </ModalInputWrapper>
                  );
                })}
                <ModalInputWrapper>
                  <InputLabel>이미지</InputLabel>
                  <Input
                    ref={imageRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                  <Image
                    onClick={handleClickImage}
                    src={
                      imagePreview ? imagePreview : selectedStuff?.image || ""
                    }
                    alt="프리뷰"
                  />
                </ModalInputWrapper>
                <ButtonWrap>
                  <Button
                    disabled={!validateStuff()}
                    onClick={handleSubmitStuff}
                  >
                    수정
                  </Button>

                  <Button backgroundColor="gray" onClick={handleCloseModal}>
                    닫기
                  </Button>
                </ButtonWrap>
              </>
            ) : null}
          </EditModalContainer>
        </Modal>
      )}
    </PageLayout>
  );
};

export default MasterAdminPage;
