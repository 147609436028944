import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePage from "../../lib/hooks/usePage";
import {
  useMasterAllStuff,
  useMasterStuffHistories,
} from "../../services/masterStuff.service";

export const useMasterStuffOutPageHook = () => {
  const { admin_id, stuff_id } = useParams();
  const { pageNum, onClickPageHandler } = usePage();

  console.log(admin_id, stuff_id);
  const endDate = dayjs().format("YYYY-MM-DD");
  const firstDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const [selectStuffId, setSelectStuffId] = useState(0);
  const [date, setDate] = useState({
    firstDate: firstDate || "",
    endDate: endDate || "",
  });
  const navigate = useNavigate();
  const firstDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);

  const { data: masterStuffHistories } = useMasterStuffHistories(
    admin_id!,
    stuff_id!,
    pageNum,
    date.firstDate,
    date.endDate,
  );

  const { data: stuffPaging } = useMasterAllStuff(admin_id!);
  const allStuff = (stuffPaging && stuffPaging.items) || [];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectStuffId(parseInt(value));
    navigate(`/master/admin/${admin_id}/stuff/${value}`);
  };

  const handleSetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDate((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickDate = (type: "firstDate" | "endDate") => {
    if (type === "firstDate") {
      firstDateRef.current?.showPicker();
      console.log("hi");
      return;
    }
    if (type === "endDate") {
      endDateRef.current?.showPicker();
      return;
    }
  };

  console.log(masterStuffHistories);

  return {
    date,
    handleSetDate,
    firstDateRef,
    endDateRef,
    handleClickDate,
    masterStuffHistories,
    allStuff,
    selectStuffId,
    handleSelectChange,
    stuff_id,
  };
};
