import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  addStuff,
  deleteStuff,
  editStuff,
  getAllStuff,
  getStuffHistory,
  GetStuffHistoryPayload,
  getStuffList,
} from "../api/stuff.api";
import useAlert from "../lib/hooks/useAlert";
import { CustomAxiosError } from "../types/axios.type";

export const useStuff = (pageNum: number) => {
  return useQuery({
    queryKey: ["stuff-list", pageNum],
    queryFn: () => getStuffList(pageNum),
  });
};

export const useAddStuff = () => {
  const { admin_id } = useParams(); // 마스터 페이지에서만 존재
  const navigate = useNavigate();
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: FormData) => addStuff(req),
    onSuccess: () => {
      showToast("success", "부자재 추가 성공", 2000);
      queryClient.invalidateQueries({
        queryKey: ["stuff-list"],
      });
      if (!admin_id) {
        navigate("/stuff/list");
      } else {
        navigate(`/master/admin/${admin_id}`);
      }
    },
    onError: (err: CustomAxiosError) => {
      const errMsg = err.response?.data.message;
      showToast("error", errMsg ? errMsg : "부자재 추가 실패", 2000);
    },
  });
};

export const useEditStuff = (handleCloseModal: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: FormData) => editStuff(req),
    onSuccess: () => {
      showToast("success", "부자재 수정 성공", 2000);
      queryClient.invalidateQueries({
        queryKey: ["stuff-list"],
      });
      handleCloseModal();
    },
    onError: (err: CustomAxiosError) => {
      const errMsg = err.response?.data.message;
      showToast("error", errMsg ? errMsg : "부자재 수정 실패", 2000);
    },
  });
};

export const useDeleteStuff = (handleCloseModal: () => void) => {
  const { showToast } = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: number) => deleteStuff(req),
    onSuccess: () => {
      showToast("success", "부자재 삭제 성공", 2000);
      queryClient.invalidateQueries({
        queryKey: ["stuff-list"],
      });
      handleCloseModal();
    },
    onError: (err: CustomAxiosError) => {
      const errMsg = err.response?.data.message;
      showToast("error", errMsg ? errMsg : "부자재 삭제 실패", 2000);
    },
  });
};

export const useGetStuffHistory = (payload: GetStuffHistoryPayload) => {
  return useQuery({
    queryKey: [
      "stuff-history",
      payload.firstDate,
      payload.endDate,
      payload.stuffId,
    ],
    queryFn: () => getStuffHistory(payload),
  });
};

export const useGetAllStuff = () => {
  return useQuery({
    queryKey: ["stuff-all"],
    queryFn: () => getAllStuff(),
  });
};
