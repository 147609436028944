import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllStuffExcelData } from "../../api/excel.api";
import useAlert from "../../lib/hooks/useAlert";
import usePage from "../../lib/hooks/usePage";
import { stuffExcel } from "../../lib/utils/excel/stuff";
import {
  useDeleteStuff,
  useEditStuff,
  useStuff,
} from "../../services/stuff.service";
import { ServerStuff } from "../../types/stuff.type";

export const useStuffList = () => {
  const navigate = useNavigate();

  const [editModal, setEditModal] = useState(false);
  const [selectedStuff, setSelectedStuff] = useState<ServerStuff>();
  const [editState, setEditState] = useState<Partial<ServerStuff>>();
  const [editImage, setEditImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState("");
  const imageRef = useRef<HTMLInputElement>(null);

  const handleCloseModal = () => {
    setEditModal(false);
    setImagePreview("");
    setEditImage(null);
  };
  const { showConfirmation } = useAlert();
  const { pageNum, onClickPageHandler } = usePage();
  const { data: stuffList } = useStuff(pageNum);
  const { mutate: editMutate } = useEditStuff(handleCloseModal);
  const { mutate: deleteMutate } = useDeleteStuff(handleCloseModal);

  const handleDeleteProduct = () => {
    showConfirmation("확인", "해당 부자재를 정말 삭제하시겠습니까?", () =>
      deleteMutate(editState!.id!),
    );
  };

  const handleNavigateToAdd = () => {
    navigate("/stuff/add");
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setEditImage(file);
      // 파일을 읽고 미리보기 이미지 생성
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string); // 미리보기 상태 업데이트
      };
      reader.readAsDataURL(file); // 파일을 Data URL로 읽기
    }
  };

  const handleClickImage = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleEditStuff = (item: ServerStuff) => {
    console.log(item);
    setSelectedStuff(item);
    setEditState(item);
    setEditModal(true);
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setEditState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    console.log(editState);
    if (!editState) return;
    const {
      id,
      name,
      size,
      unit_quantity,
      carbon_emissions,
      carbon_unit,
      memo,
    } = editState;

    if (!id || !name || !size || !unit_quantity) {
      console.log("여기");
      return;
    }

    const formData = new FormData();
    formData.append("id", id.toString());
    formData.append("name", name);
    formData.append("size", size);
    formData.append("unit_quantity", unit_quantity.toString());
    formData.append(
      "carbon_emissions",
      carbon_emissions ? carbon_emissions.toString() : "",
    );
    formData.append("carbon_unit", carbon_unit || "0");
    formData.append("memo", memo || "");

    // editImage가 있으면 이를 FormData에 추가
    if (editImage) {
      formData.append("image", editImage);
    }

    // editState의 데이터를 FormData로 전송
    editMutate(formData);
  };

  const validateStuff = () => {
    const { name, size, unit_quantity, carbon_emissions, carbon_unit } =
      editState || {};

    return Boolean(
      (name?.trim() || "").length > 0 && // 이름이 존재하고 공백이 아닌지 확인
        (size?.trim() || "").length > 0 && // 사이즈가 존재하고 공백이 아닌지 확인
        unit_quantity !== undefined &&
        unit_quantity >= 0, // 수량이 0보다 큰지 확인
      // carbon_emissions !== undefined &&
      // carbon_emissions >= 0 && // 탄소 배출량이 0보다 큰지 확인
      // (carbon_unit?.trim() || "").length > 0, // 단위가 존재하고 공백이 아닌지 확인
    );
  };

  const handleClickExcel = async () => {
    const data = await getAllStuffExcelData();
    return stuffExcel(data);
  };

  return {
    handleNavigateToAdd,
    editModal,
    selectedStuff,
    handleCloseModal,
    handleEditStuff,
    stuffList,
    pageNum,
    onClickPageHandler,
    editState,
    handleEditChange,
    imagePreview,
    imageRef,
    handleImageChange,
    handleClickImage,
    validateStuff,
    handleSubmit,
    handleDeleteProduct,
    handleClickExcel,
  };
};
